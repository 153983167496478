import React from 'react'
import { SetModal, ModalType } from '../..'
import WithdrawalModal from '../WithdrawalModal'
import { useAppSelector } from '@/redux'

import RecordItem from '../RecordItem'
import { useGetBalanceQuery, initialBalance } from '../../walletSlice'
import WithdrawTitle from '../WithdrawTitle'
import Billing from './Billing'
import useWithdrawParams from './useWithdrawParams'
export default function Monthly({
  setModal,
  modalStatus,
}: {
  setModal: SetModal
  modalStatus: ModalType
}) {
  const { data: balance = initialBalance } = useGetBalanceQuery(0)

  const { collectionInfoFilled, idenAuthenticationed } = useAppSelector(
    (state) => state.user,
  )

  const withdrawParams = useWithdrawParams()
  return (
    <div>
      <WithdrawTitle
        text={`注意：每月${withdrawParams.timeRangeRaw[0]}日~${withdrawParams.timeRangeRaw[1]}日可提现1次，提现额度￥${withdrawParams.amountLimit[0]}~${withdrawParams.amountLimit[1]}元。`}
        disabled={!withdrawParams.available}
        onClick={() =>
          setModal(
            !idenAuthenticationed
              ? 'requiredIdentify'
              : !collectionInfoFilled
              ? 'requiredCollectionInfo'
              : 'withdrawaling',
          )
        }
        title="月结钱包"
        balance={balance}
      ></WithdrawTitle>
      <Billing />
      <RecordItem type="monthly-entry"></RecordItem>
      <RecordItem type="monthly-withdraw"></RecordItem>
      {modalStatus === 'withdrawaling' && (
        <WithdrawalModal
          visible={!!modalStatus}
          onFinishWithdrawal={() => setModal('')}
          walletType={0}
          withdrawParams={withdrawParams}
        ></WithdrawalModal>
      )}
    </div>
  )
}
