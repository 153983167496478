import React from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'

import { RButton } from './RewriteComponent'

interface NoticeProps {
  children: any
  visible: boolean
  title: string
  onOk?: any
  onCancel?: any
  okText?: string
  cancelText?: string
  bodyStyle?: any
  closable?: boolean
  width?: string | number
  [propsName: string]: any
}
const CostomModal = styled(Modal)`
  .ant-modal-header {
    padding: 0;
    background: #f5f6f7;
    height: 48px;
    text-align: center;
    color: #636363;
    font-weight: 500;
    .ant-modal-title {
      height: 100%;
      line-height: 48px;
    }
  }
`

const NoticeModal = (props: NoticeProps) => {
  return (
    <CostomModal
      width={props.width || '560px'}
      maskClosable={false}
      footer={null}
      {...props}
      destroyOnClose={true}
    >
      {props.children}
      {(props.cancelText || props.okText) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
          }}
        >
          {props.cancelText && (
            <RButton style={{ marginRight: '30px' }} onClick={props.onCancel}>
              {props.cancelText}
            </RButton>
          )}
          {props.okText && (
            <RButton onClick={props.onOk} type="primary">
              {props.okText}
            </RButton>
          )}
        </div>
      )}
    </CostomModal>
  )
}

export default NoticeModal
