import { WithdrawParams } from '../WithdrawalModal'
import {
  useGetWeeklyParamsQuery,
  useGetBalanceQuery,
  initialBalance,
  initialWeeklyParams,
} from '../../walletSlice'
import { useAppSelector } from '@/redux'
import { useCallback } from 'react'
import moment from 'moment'
import { currenceFormat } from '../..'
export default function useWithdrawParams(): WithdrawParams {
  const { data: params = initialWeeklyParams } = useGetWeeklyParamsQuery()
  const { data: balance = initialBalance } = useGetBalanceQuery(1)
  const { timestamp } = useAppSelector((state) => state.user)
  //时间
  const validTime = useCallback(() => {
    //周一是1，但再momonet中要用0表示
    return moment(timestamp).isBetween(
      moment().weekday(params.getmoney_startday - 1),
      moment().weekday(params.getmoney_endday - 1),
      'day',
      '[]',
    )
  }, [params.getmoney_endday, params.getmoney_startday, timestamp])

  return {
    leftAmount: currenceFormat(balance.leftAmount),
    amountLimit: [params.getmoney_min, params.getmoney_max],
    timeRangeString: [
      `本周${params.getmoney_startday}`,
      `本周${params.getmoney_endday}`,
    ],
    timeRangeRaw: [params.getmoney_startday, params.getmoney_endday],
    available:
      validTime() && params.getmoney_min <= currenceFormat(balance.leftAmount),
  }
}
