import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { AxiosRequestConfig, AxiosError } from 'axios'
import { apiBaseurl, adminApiBaseurl, painetApiurl } from '@/config'
import { AxiosInitialInstance } from '@/utils/request'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<AxiosRequestConfig, unknown, unknown> =>
  async ({ url, ...rest }) => {
    try {
      const result = await AxiosInitialInstance({
        url: baseUrl + url,
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        ...rest,
      })
      return { data: result.data }
    } catch (axiosError) {
      let err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: {
            ...(err.response?.data || {}),
            url: err.response?.config?.url,
          },
        },
      }
    }
  }

export const apiSlice = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: apiBaseurl,
  }),
  endpoints() {
    return {}
  },
  tagTypes: ['InvoiceList', 'BillList', 'DeviceList', 'DialList'],
  reducerPath: 'commonApiSlice',
})
export default apiSlice

//供应商网关处理后，这两个slice可以合并
export const adminApiSlice = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: adminApiBaseurl,
  }),
  endpoints() {
    return {}
  },
  tagTypes: [
    'Wallet',
    'BusinessIdList',
    'FilterDeployableMachine',
    'BusinessSwitchOrderHistory',
  ],
  reducerPath: 'adminApiSlice',
})

//下面的要复用小程序的逻辑，所以域名不一样
export const painetApiSlice = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: painetApiurl,
  }),
  endpoints() {
    return {}
  },
  tagTypes: [],
  reducerPath: 'painetApiSlice',
})
