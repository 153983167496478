import { manuList } from '../views/pcenter/index'

export const routes = [
    {
        path: '/dashboard/bill',
        name: '数据总览',
        shortName: '数',
        show: true
    },
    {
        path: '/device/list',
        name: '节点列表',
        shortName: '节',
        show: true
    },
    {
        path: '/deploy',
        name: '定向业务',
        shortName: '定',
        show: true
    },
    {
        path: '/miner/list',
        name: '用户列表',
        shortName: '用',
        show: true
    },
    {
        path: '/wallet',
        name: '账单结算',
        shortName: '账',
        show: true
    },
    {
        path: '/help/bind_device',
        name: '绑定节点',
        shortName: '绑',
        show: true
    },
    {
        path: '/user',
        name: '用户中心',
        shortName: '用',
        children: manuList,
        show: true
    },
]