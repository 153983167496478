import React, { useState } from 'react'
import { Form, Input, Button, message } from 'antd'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import Card from '../common/Card'
import { $ERROR_COLOR } from '@/constants/styles'
import NoticeModal from '@/components/NoticeModal'
import { certification, CertiInfo, sendCaptchar } from '@/api/user'
import { useSelector } from 'react-redux'
import { AppState } from '@/redux'
import { Title } from '@/views/wallet'
import doCountdown from '@/utils/countdown'
import useGlobalModal from '@/route/globalModalContext'
const Comment = styled.div`
  li::before {
    content: '*';
    margin-right: 5px;
  }
  span {
    color: ${$ERROR_COLOR};
    font-weight: bold;
  }
`

const WarnMsg = () => {
  return (
    <Comment>
      <p>注意：</p>
      <ul>
        <li>
          请认真填写本人真实姓名和身份证号，
          <span>提现收款账号与实名信息保持 一致</span>，否则提现会失败；
        </li>
        <li>
          用户超过<span>5次</span>实名认证失败的，将无法使用平台的提现功能；
        </li>
        <li>
          认证后<span>不可修改</span>
        </li>
      </ul>
    </Comment>
  )
}

export default () => {
  const onFinish = (values: CertiInfo) => {
    setVisible(values)
  }
  const [visible, setVisible] = useState<null | CertiInfo>(null)
  const { idenAuthenticationed, realName, IDCardNumber, phoneNumber } =
    useSelector((state: AppState) => state.user)
  const [form] = Form.useForm()
  const history = useHistory()
  const [deadline, setDeadline] = useState(0)
  const { ScanToMiniappModal } = useGlobalModal()
  if (ScanToMiniappModal) {
    return <>{ScanToMiniappModal}</>
  }
  return (
    <>
      <Title>
        <div className="desc">实名认证</div>
      </Title>
      <Card>
        {!idenAuthenticationed && (
          <Form onFinish={onFinish} labelCol={{ span: 4 }} form={form}>
            <Form.Item
              label="真实姓名："
              name="realName"
              rules={[{ required: true, message: '请输入身份证真实姓名' }]}
            >
              <Input placeholder="请输入真实姓名" />
            </Form.Item>
            <Form.Item
              label="身份证号："
              name="IDCardNumber"
              rules={[
                { required: true, message: '请输入身份证号' },
                { len: 18, message: '身份证号格式不正确' },
              ]}
            >
              <Input placeholder="请输入身份证号" />
            </Form.Item>
            <Form.Item label="手机号码：" required>
              <Form.Item
                noStyle
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: '请输入手机号码',
                  },
                  { type: 'string', len: 11 },
                ]}
              >
                <Input
                  style={{ width: '60%' }}
                  placeholder="请输入手机号码"
                ></Input>
              </Form.Item>
              {!deadline && (
                <Button
                  type="link"
                  style={{
                    marginLeft: '10px',
                  }}
                  onClick={() => {
                    form.validateFields(['phoneNumber']).then((res) => {
                      sendCaptchar(res.phoneNumber).then(() => {
                        doCountdown({
                          deadline: 60,
                          setNumber: setDeadline,
                          callback: () => {},
                        })
                      })
                    })
                  }}
                >
                  发送验证码
                </Button>
              )}
              {!!deadline && (
                <Button
                  type="link"
                  disabled
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  {deadline}秒后重试
                </Button>
              )}
            </Form.Item>
            <Form.Item
              label="验证码："
              name="verifyCode"
              rules={[{ required: true, message: '请输入验证码' }]}
            >
              <Input placeholder="请输入请输入验证码" />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 24 }}>
              <WarnMsg></WarnMsg>
            </Form.Item>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button block type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        )}
        {idenAuthenticationed && (
          <div>
            <div>真实姓名：{realName}</div>
            <div>身份证号：{IDCardNumber.replace(/.{6}$/, '******')}</div>
            <div>手机号码：{phoneNumber}</div>
          </div>
        )}

        {!!visible && (
          <NoticeModal
            title="实名认证提示"
            okText="确定"
            onOk={() => {
              certification(visible as CertiInfo).then(() => {
                message.success('实名认证成功，请填写您的收款信息吧！', 3)
                setTimeout(() => {
                  history.push('/user/beneficiary')
                }, 3000)
              })
              setVisible(null)
            }}
            cancelText="取消"
            onCancel={() => {
              setVisible(null)
            }}
            closable={false}
            visible={!!visible}
          >
            <WarnMsg></WarnMsg>
          </NoticeModal>
        )}
      </Card>
    </>
  )
}
