import moment from 'moment'
import { WithdrawItem } from '@/redux/withdraw'

export const getBillTimeRange: (param: {
  collectionMethod?: 'personalAlipay' | 'personalCard' | 'enterpriseCard'
  withdrawParams: WithdrawItem[]
}) => [number, number] = ({ collectionMethod, withdrawParams }) => {
  const withdrawParam =
    collectionMethod === 'enterpriseCard'
      ? withdrawParams[0]
      : withdrawParams[1]
  return [withdrawParam.checkbill_startday, withdrawParam.checkbill_endday]
}

export const getWithdrawalTimeRange: (param: {
  collectionMethod?: 'personalAlipay' | 'personalCard' | 'enterpriseCard'
  withdrawParams: WithdrawItem[]
}) => [number, number] = ({ collectionMethod, withdrawParams }) => {
  const withdrawParam =
    collectionMethod === 'enterpriseCard'
      ? withdrawParams[0]
      : withdrawParams[1]
  return [withdrawParam.getmoney_startday, withdrawParam.getmoney_endday]
}

export const getWithdrawAmountLimit: (param: {
  collectionMethod?: 'personalAlipay' | 'personalCard' | 'enterpriseCard'
  withdrawParams: WithdrawItem[]
}) => [number, number] = ({ collectionMethod, withdrawParams }) => {
  const withdrawParam =
    collectionMethod === 'enterpriseCard'
      ? withdrawParams[0]
      : withdrawParams[1]
  return [
    Number((withdrawParam.getmoney_min / 100).toFixed(2)),
    Number((withdrawParam.getmoney_max / 100).toFixed(2)),
  ]
}

export const checkBillTime: (param: {
  collectionMethod?: 'personalAlipay' | 'personalCard' | 'enterpriseCard'
  withdrawParams: WithdrawItem[]
  timestamp?: number
}) => boolean = ({
  collectionMethod,
  withdrawParams,
  timestamp = Date.now(),
}) => {
  const withdrawParam =
    collectionMethod === 'enterpriseCard'
      ? withdrawParams[0]
      : withdrawParams[1]
  return moment(timestamp).isBetween(
    moment().date(withdrawParam.checkbill_startday),
    moment().date(withdrawParam.checkbill_endday),
    'day',
    '[]',
  )
}

export const checkWithdrawalTime: (param: {
  collectionMethod?: 'personalAlipay' | 'personalCard' | 'enterpriseCard'
  withdrawParams: WithdrawItem[]
  timestamp?: number
}) => boolean = ({
  collectionMethod,
  withdrawParams,
  timestamp = Date.now(),
}) => {
  const withdrawParam =
    collectionMethod === 'enterpriseCard'
      ? withdrawParams[0]
      : withdrawParams[1]
  return moment(timestamp).isBetween(
    moment().date(withdrawParam.getmoney_startday),
    moment().date(withdrawParam.getmoney_endday),
    'day',
    '[]',
  )
}
