import React from 'react'
import { Button } from 'antd'
import { Title } from '..'
import styled from 'styled-components'
import { Statistic } from '@/views/dashboard/components/BillStatistic'
import IconAccumulate from '@/assets/images/icon-accumulate-withdrawal.png'
import IconAvailable from '@/assets/images/icon-available-balance.png'
const WarnDiv = styled.div`
  margin-left: auto;
  color: ${(props) => props.theme.errorColor};
`
export default function WithdrawTitle({
  text,
  disabled,
  onClick,
  title,
  balance,
}: {
  text: string
  disabled: boolean
  onClick: () => void
  title: string
  balance: { leftAmount: number; withdrawalAmount: number }
}) {
  return (
    <>
      <Title>
        <div className="desc">{title}</div>
        <WarnDiv>{text}</WarnDiv>
        <Button type="primary" disabled={disabled} onClick={onClick}>
          提现
        </Button>
      </Title>
      <div style={{ display: 'flex' }}>
        <Statistic
          iconSrc={IconAvailable}
          label="可提现余额（元）"
          value={balance.leftAmount}
        />
        <Statistic
          iconSrc={IconAccumulate}
          label="累计提现（元）"
          value={balance.withdrawalAmount}
        />
      </div>
    </>
  )
}
