import React from 'react'
import styled from 'styled-components'
import { Tag } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import qrcode from '@/assets/images/miniapp-qr.jpg'
const Modal = styled.div`
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.45); */
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    .qr-code {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

const ScanToMiniapp: React.FC = () => {
  return (
    <Modal>
      <div>
        <Tag color="red" icon={<InfoCircleOutlined />}>
          您尚未完成实名认证，如需提现，请先登陆派享云小程序进行实名认证！
        </Tag>
        <div className="qr-code">
          <img width={200} height={200} src={qrcode} alt="" />
          <div style={{ marginTop: 10 }}>派享云小程序</div>
        </div>
      </div>
    </Modal>
  )
}

export default ScanToMiniapp
