import { PayInfo } from '@/api/user'

export interface UserInfo {
  phoneNumber: string
  username: string
  agentAccount: string
  role: string
  agentBandwidthPrice: number
  token: string
  needReconcileUser: boolean
  collectionInfoFilled: boolean
  idenAuthenticationed: boolean
  IDCardNumber: string
  realName: string
  taxRatio: number
  timestamp: number
  walletPasswordFilled: boolean
  payInfo: PayInfo
  hasPricingRight: boolean
  needFillBillingCycle: boolean
  needFillCollection: boolean //是否需要填写设备信息收集表
}

export const SET_ACCOUNTINFO = 'SET_ACCOUNTINFO'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const MUTATION_STAMP = 'mutation-stamp'
export const SET_PAYINFO = 'set-payinfo'

interface SET_ACCOUNTINFO_ACTION {
  type: typeof SET_ACCOUNTINFO
  userInfo: Omit<UserInfo, 'token' | 'needReconcileUser'>
}

interface LOGIN_ACTION {
  type: typeof LOGIN
  token: string
}

interface LOGOUT_ACTION {
  type: typeof LOGOUT
}

interface MUTATION_STAMP_ACTION {
  timestamp: number
  type: typeof MUTATION_STAMP
}
interface SET_PAYINFO_ACTION {
  payInfo: PayInfo
  type: typeof SET_PAYINFO
}

export type UserActionTypes =
  | SET_ACCOUNTINFO_ACTION
  | LOGIN_ACTION
  | LOGOUT_ACTION
  | MUTATION_STAMP_ACTION
  | SET_PAYINFO_ACTION
