import cookies from 'js-cookie'

import {
  UserInfo,
  SET_ACCOUNTINFO,
  LOGOUT,
  LOGIN,
  MUTATION_STAMP,
  SET_PAYINFO,
} from './types'
import Axios from 'axios'
import { store } from '@/route/App'
import { AGENT_LISTS } from '@/constants/config'

const defaultUserInfo = () => ({
  phoneNumber: '',
  username: '',
  agentAccount: '',
  role: '',
  agentBandwidthPrice: 0,
  token: localStorage.getItem('token') ?? '',
  needReconcileUser: false, //是否是需要对账的用户，包括代理和小派下的用户
  collectionInfoFilled: false, //是否填写收款信息
  idenAuthenticationed: false, //是否实名认证
  needFillBillingCycle: false, //是否需要弹窗选择周结还是月结
  realName: '',
  IDCardNumber: '',
  taxRatio: 0, //税率
  timestamp: Date.now(),
  walletPasswordFilled: false,
  payInfo: {
    bankAccount: '',
    bankName: '',
    cardNumber: '',
    collectionMethod: undefined,
    collectionNumber: '',
    contractType: '',
    invoiceType: 'specialSix' as 'specialSix',
    phoneNumber: '',
    billingCycle: '',
  },
  hasPricingRight: false,
  needFillCollection: false,
})

export const evalNeedReconcileUser = (state: any) => {
  return (
    (state.role === 'agent' && !AGENT_LISTS.includes(state.username)) ||
    (state.role === 'miner' && AGENT_LISTS.includes(state.agentAccount))
  )
}

const initialState: UserInfo = defaultUserInfo()
let interval: any = null
const mutationTime = (timestamp: number) => {
  const timerSpan = 1000 * 60 * 10 //十分钟同步一次时间
  if (interval) {
    clearInterval(interval)
  }
  interval = setInterval(() => {
    timestamp = timestamp + timerSpan
    store.dispatch({
      type: MUTATION_STAMP,
      timestamp,
    })
  }, timerSpan)
}

export function userReducer(
  state = initialState,
  action: {
    type: string
    [propsName: string]: any
  },
): UserInfo {
  switch (action.type) {
    case SET_ACCOUNTINFO:
      mutationTime(action.userInfo.timestamp * 1000)
      return {
        ...state,
        ...action.userInfo,
        needReconcileUser: evalNeedReconcileUser(action.userInfo),
        timestamp: action.userInfo.timestamp * 1000,
      }
    case LOGIN:
      localStorage.setItem('token', action.token)
      Axios.defaults.headers['Authorization'] = `Bearer ${action.token}`
      return {
        ...state,
        token: action.token,
      }
    case LOGOUT:
      localStorage.removeItem('token')
      localStorage.removeItem('phoneNumber')
      // 客服数据
      localStorage.removeItem('_uid')
      localStorage.removeItem('realName')
      localStorage.removeItem('agentAccount')
      localStorage.removeItem('username')
      cookies.remove(`ticket-${state.username}`)
      Axios.defaults.headers['Authorization'] = ''
      return defaultUserInfo()
    case MUTATION_STAMP:
      return {
        ...state,
        timestamp: action.timestamp,
      }
    case SET_PAYINFO:
      return {
        ...state,
        payInfo: action.payInfo,
      }
    default:
      return state
  }
}
