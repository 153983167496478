import { apiSlice } from '@/api/apiSlice'

export interface Bank {
  fName: string
}

const infoSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    searchBank: build.mutation<Bank[], string>({
      query: (fName) => {
        return {
          url: '/metadata/v1/kingdeeBanks',
          params: { fName },
        }
      },
      transformResponse: (response: { list: Bank[] }) => {
        return response.list
      },
    }),
  }),
})
export const { useSearchBankMutation } = infoSlice
