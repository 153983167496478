import React from 'react'
import { Modal, Popover } from 'antd'
import styled from 'styled-components'

interface NoticeProps {
  children: any
  visible: boolean
  title: string
  onOk?: any
  onCancel?: any
  cancelPopoverContent?: string
  hideButton?: boolean
  closable?: boolean
}
const CostomModal = styled(Modal)`
  .ant-modal-header {
    padding: 0;
    background: #f5f6f7;
    height: 48px;
    text-align: center;
    color: #636363;
    font-weight: 500;
    .ant-modal-title {
      height: 100%;
      line-height: 48px;
    }
  }
`
const Button = styled.div`
  width: 104px;
  height: 32px;
  line-height: 32px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: center;
  background: rgba(245, 246, 247, 1);
  cursor: pointer;
  display: inline-block;
`
const BillNoticeModal = (props: NoticeProps) => {
  return (
    <CostomModal maskClosable={false} footer={null} {...props}>
      {props.children}
      {!props.hideButton && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
          }}
        >
          <Button
            onClick={props.onOk}
            style={{
              background: '#2E5BFF',
              color: '#fff',
              marginRight: '42px',
            }}
          >
            同意
          </Button>
          <Popover content={props.cancelPopoverContent}>
            <Button onClick={props.onCancel}>不同意</Button>
          </Popover>
        </div>
      )}
    </CostomModal>
  )
}

export default BillNoticeModal
