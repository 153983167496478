import { userReducer } from './user/reducers'
import { combineReducers } from 'redux'
import billReducer from './bill/reducer'
import { withdrawReducer } from './withdraw'
import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux'
import apiSlice, { adminApiSlice, painetApiSlice } from '@/api/apiSlice'
import { rtkQueryErrorLogger } from './middleware/errHandle'
import { LOGOUT } from './user/types'
const rootReducer = combineReducers({
  user: userReducer,
  billInfo: billReducer,
  withdrawParams: withdrawReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [adminApiSlice.reducerPath]: adminApiSlice.reducer,
  [painetApiSlice.reducerPath]: painetApiSlice.reducer,
})

const store = configureStore({
  reducer: (state, action) => {
    //退出时清空store，否则不同用户数据会混乱
    if (action.type === LOGOUT) {
      state = undefined
    }
    return rootReducer(state, action)
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiSlice.middleware,
      adminApiSlice.middleware,
      painetApiSlice.middleware,
      rtkQueryErrorLogger,
    ),
})
export default store
export type AppState = ReturnType<typeof rootReducer>
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector

export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
