import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import useQueryData from '../hooks/useQueryData'
import styled from 'styled-components'
const Line = styled.div`
  margin-top: auto;
  margin-bottom: 10px;
  span:first-child {
    margin-right: 6px;
  }
  span:last-child {
    color: red;
  }
`

export default function Tips() {
  const { hasBinded } = useQueryData()
  if (hasBinded) {
    return (
      <Line>
        <InfoCircleOutlined></InfoCircleOutlined>
        注:如您需要重新选择管理线路网卡，请<span>先解绑已选网卡</span>
      </Line>
    )
  }
  return (
    <Line>
      <InfoCircleOutlined></InfoCircleOutlined>
      注：如您无法确定管理线路网卡，请
      <span>拔掉数据线路，仅保留管理线路</span>
    </Line>
  )
}
