import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit'
import { message } from 'antd'

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      const data = action?.payload?.data
      if (data) {
        //这里只处理没登录时接口的错误，其他错误已经在对应地方处理
        if (
          [
            'help/v1',
            '/account/v1/saveAddressInfo',
            '/account/v1/assistant/inputtips',
          ].some((u) => data.url?.includes(u)) &&
          data.message
        ) {
          console.warn('We got a rejected action!')
          message.error(data.message)
        }
      }
    }

    return next(action)
  }
