import { SET_ACCOUNTINFO, LOGOUT, UserInfo, LOGIN } from './types'

export function setAccoutInfo(
  userInfo: Omit<UserInfo, 'token' | 'needReconcileUser'>,
) {
  return {
    type: SET_ACCOUNTINFO,
    userInfo,
  }
}

export function login(token: string) {
  return {
    type: LOGIN,
    token,
  }
}

export function logout() {
  return {
    type: LOGOUT,
  }
}
