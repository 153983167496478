import apiSlice from '@/api/apiSlice'

interface UserInfo {
  needVerify: boolean
}

const accountSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserInfo: build.query<UserInfo, void>({
      query: () => {
        return {
          url: '/account/v1/info',
        }
      },
      transformResponse: (response: UserInfo) => {
        return { ...response }
      },
    }),
  }),
})

export const { useGetUserInfoQuery } = accountSlice
