export function showCurrentLabel<T>(
  list: { label: string; value: T }[],
  value?: T | T[],
): string | T {
  if (Array.isArray(value)) {
    return value.map((item) => showCurrentLabel(list, item)).join()
  } else {
    const target = list.find((item) => item.value === value)
    return target ? target.label : value ?? ''
  }
}
//将['a','b']转为[{label:'a',value:'a'},{label:'b',value:'b'}]这种格式
export function tuple2kv(arr: string[]) {
  return arr.map((item) => {
    return {
      label: item,
      value: item,
    }
  })
}
