import React from 'react'
import { Table } from 'antd'
import { useAsync } from '@umijs/hooks'
import { getRecommendImagePackage } from '../../utils/api'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import BindDeviceImage from '../../assets/images/how-to-bind-device.png'

const Box = styled.div`
  > .title {
    font-size: 22px;
    line-height: 22px;
    margin-top: 40px;
    margin-bottom: 18px;
  }
  .card {
    padding: 24px 32px 16px;
    background-color: #fff;
    margin-bottom: 24px;
    > .title {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 28px;
      margin-bottom: 16px;
    }
    .ant-form-item-control-input,
    .ant-input-number {
      width: 468px;
    }
  }
`

const BindDevice: React.FC<{}> = () => {
  const { loading: isGettingRecImage, data: recommendImagePackage } = useAsync(
    getRecommendImagePackage,
  )

  return (
    <Box>
      <div className="title">如何刷镜像</div>
      <div className="card">
        <Table
          rowKey="md5"
          columns={[
            {
              title: '派享云镜像包版本',
              dataIndex: 'version',
            },
            {
              title: '镜像包下载地址',
              dataIndex: 'downloadURL',
            },
            {
              title: 'MD5校验码',
              dataIndex: 'md5',
            },
          ]}
          dataSource={[
            recommendImagePackage || {
              downloadURL: '',
              md5: '',
              version: '',
            },
          ]}
          loading={isGettingRecImage}
          pagination={false}
          style={{ marginBottom: 10 }}
          bordered
        />
        <Link to="/help/doc/doc-x86" target="_blank" rel="noopener noreferrer">
          戳我查看刷镜像教程
        </Link>
      </div>
      <div className="title">如何绑定设备</div>
      <div
        className="card"
        style={{ padding: '48px 96px', paddingRight: '40%' }}
      >
        <img
          src={BindDeviceImage}
          alt="how-to-bind-device"
          style={{ width: '100%' }}
        />
      </div>
    </Box>
  )
}

export default BindDevice
