import Axios, { AxiosInstance } from 'axios'
import {
  apiBaseurl,
  sffApiBaseurl,
  strapiBaseurl,
  walletApiBaseurl,
  noticeApiBaseurl,
  accountApiBaseurl,
} from '../config'

export const AxiosInitialInstance = Axios.create() //用http状态码进行处理

type AxiosPost = typeof Axios.post
type AxiosGet = typeof Axios.get

export const axiosPost: AxiosPost = (url, data, config) => {
  return AxiosInitialInstance.post(`${apiBaseurl}/v1${url}`, data, {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

export const axiosGet: AxiosGet = (url, config) => {
  return AxiosInitialInstance.get(`${apiBaseurl}/v1${url}`, {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

export const axiosDelete: AxiosInstance['delete'] = (url, config) => {
  return AxiosInitialInstance.delete(`${apiBaseurl}/v1${url}`, {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

export const axiosNoticeGet: AxiosGet = (url, config) => {
  return AxiosInitialInstance.get(`${noticeApiBaseurl}/v1${url}`, {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

export const axiosWalletGet: AxiosGet = (url, config) => {
  return AxiosInitialInstance.get(`${walletApiBaseurl}${url}`, {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

export const axiosWalletPost: AxiosPost = (url, data, config) => {
  return AxiosInitialInstance.post(`${walletApiBaseurl}${url}`, data, {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}
export const axiosAccountGet: AxiosGet = (url, config) => {
  return AxiosInitialInstance.get(`${accountApiBaseurl}/v1${url}`, {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

export const axiosAccountPost: AxiosPost = (url, data, config) => {
  return AxiosInitialInstance.post(`${accountApiBaseurl}/v1${url}`, data, {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

export const axiosSffGet: AxiosGet = (url, config) => {
  return AxiosInitialInstance.get(`${sffApiBaseurl}${url}`, {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

export const axiosSffPost: AxiosPost = (url, data, config) => {
  return AxiosInitialInstance.post(`${sffApiBaseurl}${url}`, data, {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

export const axiosStrapiGet: AxiosGet = (url) => {
  return AxiosInitialInstance.get(`${strapiBaseurl}${url}`)
}
