import { BillStatus } from '@/utils/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
const initialInfo: {
  status?: string
  statusCode?: BillStatus
} = {
  status: '', //账单状态
  statusCode: 0, //账单状态码
}

const billSlice = createSlice({
  name: 'bill',
  initialState: initialInfo,
  reducers: {
    updateBillStatus(
      state,
      action: PayloadAction<{ stateName?: string; stateCode?: BillStatus }>,
    ) {
      state.status = action.payload.stateName
      state.statusCode = action.payload.stateCode
    },
  },
})

export const { updateBillStatus } = billSlice.actions
export default billSlice.reducer
