import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Select, message, Card as AntdCard } from 'antd'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { throttle } from 'lodash'
import Card from '../common/Card'
import { $Link_COLOR, $ERROR_COLOR } from '@/constants/styles'
import BindModal from './bindModal'
import ConfirmModal from './confirmModal'
import CertificationModal from './certificationModal'
import doCountdown from '@/utils/countdown'
import { AppState } from '@/redux'
import { checkBillTime, getBillTimeRange } from '@/utils/checkWithdrawParams'
import { PaymentInfo, sendCaptchar, updatePaymentInfo } from '@/api/user'
import { WithdrawItem } from '@/redux/withdraw'
import { Title } from '@/views/wallet'
import { BillStatus } from '@/utils/api'
import { RButton } from '@/components/RewriteComponent'
import { showCurrentLabel } from '@/utils/gadget'
import * as constants from '../common/constants'
import type { collectionMethodType } from '../common/constants'
import { getBankNameByCard } from '@/utils/api'
import { WeeklyText } from '@/views/wallet/components/weekly/Desc'
import SearchAddress from './SearchAddress'
import useGlobalModal from '@/route/globalModalContext'
const { Option } = Select
export const BeneForm = styled(Form)`
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
`
const WarnMsg = styled(Form.Item)`
  .ant-form-item-control-input-content {
    flex-wrap: wrap;
    span {
      color: ${$ERROR_COLOR};
    }
  }
`

const InfoList = styled(Card)`
  border: none;
  .ant-card-body > div {
    margin-bottom: 10px;
  }
`

const billingCycleOptions = [
  { label: '周结', value: 'week' },
  { label: '月结', value: 'month' },
]
export default React.memo(() => {
  const { ScanToMiniappModal } = useGlobalModal()
  const {
    collectionInfoFilled,
    idenAuthenticationed,
    realName,
    phoneNumber,
    username,
    timestamp,
    payInfo,
  } = useSelector((state: AppState) => state.user)

  const withdrawParams = useSelector(
    (state: { withdrawParams: WithdrawItem[] }) => state.withdrawParams,
  )

  const { statusCode } = useSelector(
    (state: { billInfo: any }) => state.billInfo,
  )
  const [form] = Form.useForm()
  const [outdate, setDate] = useState(true)

  const [modalStatus, setModal] = useState<'' | 'confirm' | 'bind'>('')

  useEffect(() => {
    // if (
    //   (checkBillTime({
    //     timestamp,
    //     withdrawParams,
    //     collectionMethod: payInfo.collectionMethod,
    //   }) &&
    //     statusCode !== BillStatus.FAAudited &&
    //     statusCode !== BillStatus.Settled) ||
    //   !collectionInfoFilled
    // ) {
    if (
      (statusCode !== BillStatus.FAAudited &&
        statusCode !== BillStatus.Settled) ||
      !collectionInfoFilled
    ) {
      setDate(false)
    } else {
      setDate(true)
    }
  }, [withdrawParams, timestamp, payInfo, statusCode, collectionInfoFilled])

  const initialValues: PaymentInfo = {
    phoneNumber,
    verifyCode: '',
    collectionMethod: undefined,
    collectionNumber: '',
    bankAccount: '',
    bankName: '',
    cardNumber: '',
    invoiceType: 'specialSix',
    billingCycle: '',
  }
  const [type, setType] = useState<collectionMethodType | undefined>(
    initialValues.collectionMethod,
  )
  const [editStatus, setEdit] = useState(!collectionInfoFilled) //表示页面的展示状态还是编辑状态

  const [validCapchar, setCaptstatus] = useState(true)
  const [deadline, setDeadline] = useState(0)
  const [changeBind, setChangeBind] = useState(false)

  useEffect(() => {
    if (!changeBind) {
      // 换绑手机号时，关闭弹窗不能刷新页面的值，要保持原来界面填的值
      form.setFieldsValue({
        ...payInfo,
        collectionMethod: payInfo.collectionMethod || undefined,
        invoiceType: payInfo.invoiceType || 'specialSix',
        verifyCode: '',
        bankName2: payInfo.bankName,
        phoneNumber,
      })
      setType(payInfo.collectionMethod || undefined)
    } else {
      // 如果换绑了，那就需要更新换绑后的手机号
      form.setFieldsValue({
        phoneNumber,
      })
    }
  }, [collectionInfoFilled, JSON.stringify(payInfo)])

  useEffect(() => {
    if (validCapchar) return
    form.validateFields(['verifyCode'])
    setCaptstatus(true)
  }, [validCapchar, form])

  // 银行卡号变化调用查询接口获取填充开户行
  const collectionNumberChange = throttle(async (value: string | undefined) => {
    if (value === undefined || value === '') return
    if (value.trim().length < 16 || value.trim().length > 19) return
    try {
      const result = await getBankNameByCard(value.replace(/\s+/g, ''))
      result &&
        form.setFieldsValue({
          bankName2: result.bankName,
          collectionNumber: value.replace(/\s+/g, ''),
        })
    } catch {}
  }, 500)
  // 换绑手机号时，关闭弹窗不能刷新页面的值，要保持修改的值
  const handleBindClose = () => {
    setChangeBind(true)
  }
  if (ScanToMiniappModal) {
    return <>{ScanToMiniappModal}</>
  }
  return (
    <>
      <Title>
        <div className="desc">收款信息</div>
      </Title>
      {editStatus && (
        <Card>
          <BeneForm
            initialValues={initialValues}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            labelAlign="left"
            form={form}
            onFinish={() => {
              setModal('confirm')
            }}
          >
            <Form.Item
              label="收款方式："
              name="collectionMethod"
              rules={[{ required: true, message: '请输入收款方式' }]}
            >
              <Select
                placeholder="请选择收款方式"
                onChange={(e: collectionMethodType) => {
                  setType(e)
                }}
                disabled={
                  outdate || payInfo.collectionMethod === 'enterpriseCard'
                }
              >
                {payInfo.collectionMethod === 'personalAlipay' ||
                payInfo.collectionMethod === 'personalCard'
                  ? constants.COLLECTION_METHOD_OPTIONS.filter(
                      (one) => one.value !== 'enterpriseCard',
                    ).map((item: { label: string; value: string }) => {
                      return (
                        <Option value={item.value} key={item.label}>
                          {item.label}
                        </Option>
                      )
                    })
                  : constants.COLLECTION_METHOD_OPTIONS.map(
                      (item: { label: string; value: string }) => {
                        return (
                          <Option value={item.value} key={item.label}>
                            {item.label}
                          </Option>
                        )
                      },
                    )}
              </Select>
            </Form.Item>
            {type === 'personalAlipay' && (
              <>
                <WarnMsg wrapperCol={{ span: 24 }}>
                  注意：个人支付宝收款，<span>统一扣税6%</span>
                  ；为确保提现成功，该支付宝<span>开户姓名必须</span>
                  <span>为{realName}</span>
                </WarnMsg>
                <Form.Item
                  rules={[{ required: true, message: '请输入支付宝账号' }]}
                  label="支付宝账号"
                  name="collectionNumber"
                >
                  <Input
                    readOnly={outdate}
                    placeholder="请输入支付宝账号"
                  ></Input>
                </Form.Item>
              </>
            )}
            {type === 'personalCard' && (
              <>
                <WarnMsg wrapperCol={{ span: 24 }}>
                  注意：个人银行卡收款，<span>统一扣税6%</span>
                  ；为确保提现成功，该银行卡<span>开户姓名必须</span>
                  <span>为{realName}</span>
                </WarnMsg>
                <Form.Item
                  name="collectionNumber"
                  label="银行卡号"
                  rules={[{ required: true, message: '请输入银行卡号' }]}
                >
                  <Input
                    onChange={(e) =>
                      collectionNumberChange(e.currentTarget.value)
                    }
                    readOnly={outdate}
                    placeholder="请输入银行卡号"
                  ></Input>
                </Form.Item>
                <Form.Item
                  name="bankName2"
                  label="开户行"
                  rules={[{ required: true, message: '请输入开户行' }]}
                >
                  <Input readOnly={outdate} placeholder="请输入开户行"></Input>
                </Form.Item>
              </>
            )}
            {(type === 'personalAlipay' || type === 'personalCard') && (
              <>
                <Form.Item
                  label="预期结算周期"
                  name="billingCycle"
                  rules={[{ required: true, message: '请选择预期结算周期' }]}
                >
                  <Select>
                    {billingCycleOptions.map((item) => {
                      return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.billingCycle !== curValues.billingCycle
                  }
                >
                  {() => {
                    if (form.getFieldValue('billingCycle') === 'week') {
                      return (
                        <div style={{ marginBottom: '16px' }}>
                          <WeeklyText />
                        </div>
                      )
                    }
                  }}
                </Form.Item>
              </>
            )}
            {type === 'enterpriseCard' && (
              <>
                <WarnMsg wrapperCol={{ span: 24 }}>
                  注意：使用企业银行账户收款，请确保已与派享云签订合同，否则提现将无效
                </WarnMsg>
                <Form.Item
                  name="bankAccount"
                  label="银行账户名"
                  rules={[{ required: true, message: '请输入银行账户名' }]}
                >
                  <Input
                    readOnly={outdate}
                    placeholder="请输入银行账户名"
                  ></Input>
                </Form.Item>
                <SearchAddress></SearchAddress>
                <Form.Item
                  name="cardNumber"
                  label="银行卡号"
                  rules={[{ required: true, message: '请输入银行卡号' }]}
                >
                  <Input
                    readOnly={outdate}
                    placeholder="请输入银行卡号"
                  ></Input>
                </Form.Item>
                <Form.Item
                  name="invoiceType"
                  label="发票类型"
                  rules={[{ required: true, message: '请输入发票类型' }]}
                >
                  <Select disabled={outdate}>
                    {constants.INVOICE_TYPES_OPTIONS.map(
                      (item: { label: string; value: string }) => {
                        return (
                          <Option value={item.value} key={item.label}>
                            {item.label}
                          </Option>
                        )
                      },
                    )}
                  </Select>
                </Form.Item>
                <WarnMsg wrapperCol={{ span: 24 }}>
                  注意：开6%专票，<span>平台不扣税</span>
                  ；开普票、1%或3%的专票，<span>平台统一代扣3%的税</span>
                </WarnMsg>
              </>
            )}
            <Form.Item label="提现手机号：">
              <Form.Item noStyle name="phoneNumber">
                <Input
                  readOnly
                  style={{ width: '60%', border: 'none' }}
                ></Input>
              </Form.Item>
              {!outdate && (
                <span
                  style={{
                    marginLeft: 'auto',
                    color: $Link_COLOR,
                    cursor: 'pointer',
                  }}
                  onClick={() => setModal('bind')}
                >
                  修改
                </span>
              )}
              {!deadline && !outdate && (
                <span
                  style={{
                    marginLeft: '10px',
                    color: $Link_COLOR,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    sendCaptchar(form.getFieldValue('phoneNumber')).then(() => {
                      doCountdown({
                        deadline: 60,
                        setNumber: setDeadline,
                        callback: () => {},
                      })
                    })
                  }}
                >
                  发送验证码
                </span>
              )}
              {!!deadline && (
                <span
                  style={{
                    marginLeft: '10px',
                    color: '#666',
                    cursor: 'not-allowed',
                  }}
                >
                  {deadline}秒后重试
                </span>
              )}
            </Form.Item>
            <Form.Item
              label="验证码："
              name="verifyCode"
              rules={[
                { required: true, message: '请输入验证码' },
                () => ({
                  validator(rule, value) {
                    if (!value || (value && validCapchar)) {
                      return Promise.resolve()
                    }
                    return Promise.reject('验证码错误')
                  },
                }),
              ]}
            >
              <Input readOnly={outdate} placeholder="请输入6位验证码" />
            </Form.Item>
            {/* <Form.Item wrapperCol={{ span: 24 }}>
              仅
              <span style={{ color: $ERROR_COLOR }}>
                {`每月${
                  getBillTimeRange({
                    withdrawParams,
                    collectionMethod: payInfo.collectionMethod,
                  })[0]
                }日~${
                  getBillTimeRange({
                    withdrawParams,
                    collectionMethod: payInfo.collectionMethod,
                  })[1]
                }日`}
              </span>
              可修改收款信息，提现账户以提现时的收款信息为准
            </Form.Item> */}
            <div style={{ justifyContent: 'space-evenly', display: 'flex' }}>
              <Button disabled={outdate} onClick={() => setEdit(false)}>
                取消
              </Button>
              <Button disabled={outdate} type="primary" htmlType="submit">
                确定
              </Button>
            </div>
          </BeneForm>
          {modalStatus === 'confirm' && (
            <ConfirmModal
              onCancel={() => {
                setModal('')
              }}
              onOk={() => {
                const params =
                  type === 'personalCard'
                    ? {
                        ...form.getFieldsValue(),
                        bankName: form.getFieldValue('bankName2'),
                        collectionNumber: form
                          .getFieldValue('collectionNumber')
                          .replace(/\s+/g, ''),
                      }
                    : form.getFieldsValue()
                updatePaymentInfo(params)
                  .then(() => {
                    message.success('收款信息提交成功')
                    setModal('')
                    setEdit(false)
                  })
                  .catch((err) => {
                    if (err?.response?.data === '验证码错误') {
                      setCaptstatus(false)
                    }
                  })
              }}
              visible={modalStatus}
            ></ConfirmModal>
          )}
          {modalStatus === 'bind' && (
            <BindModal
              username={username}
              setModal={setModal}
              phoneNumber={phoneNumber}
              visible={modalStatus}
              handleBindClose={handleBindClose}
            ></BindModal>
          )}
        </Card>
      )}
      {!editStatus && (
        <AntdCard>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {/* <div style={{ marginLeft: 'auto' }}>
              仅
              <span style={{ color: $ERROR_COLOR }}>
                {`每月${
                  getBillTimeRange({
                    withdrawParams,
                    collectionMethod: payInfo.collectionMethod,
                  })[0]
                }日~${
                  getBillTimeRange({
                    withdrawParams,
                    collectionMethod: payInfo.collectionMethod,
                  })[1]
                }日`}
              </span>
              可修改收款信息，提现账户以提现时的收款信息为准
            </div> */}
            <RButton
              style={{ marginLeft: '20px' }}
              type="primary"
              onClick={() => setEdit(true)}
              disabled={outdate}
            >
              修改
            </RButton>
          </div>
          <InfoList>
            <div>
              收款方式：
              {showCurrentLabel(
                constants.COLLECTION_METHOD_OPTIONS,
                payInfo.collectionMethod,
              )}
            </div>
            <div>提现手机号：{phoneNumber}</div>
            {type === 'enterpriseCard' ? (
              <>
                <div>银行账户名：{payInfo.bankAccount}</div>
                <div>开户行：{payInfo.bankName}</div>
                <div>银行卡号：{payInfo.cardNumber}</div>
                <div>
                  发票类型：
                  {showCurrentLabel(
                    constants.INVOICE_TYPES_OPTIONS,
                    payInfo.invoiceType,
                  )}
                </div>
              </>
            ) : (
              <>
                <div>收款账号：{payInfo.collectionNumber}</div>
                <div>
                  预期结算周期：
                  {showCurrentLabel(billingCycleOptions, payInfo.billingCycle)}
                </div>
              </>
            )}
          </InfoList>
        </AntdCard>
      )}
    </>
  )
})
