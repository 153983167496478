import React from 'react'
import { Button, Spin } from 'antd'
import useQueryData from '../hooks/useQueryData'
export default function Empty() {
  const { refetch, isFetching } = useQueryData()
  return (
    <Spin spinning={isFetching}>
      <div
        style={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ color: '#666' }}>
          暂未检测到设备的网卡信息，请确保你的网卡正常启动！
        </div>
        <Button
          onClick={() => {
            refetch()
          }}
          type="link"
        >
          刷新
        </Button>
      </div>
    </Spin>
  )
}
