import { Table } from 'antd'
import styled from 'styled-components'

export default styled(Table)`
  text-align: center;

  tr > th {
    font-size: 12px;
    line-height: 16px;
    color: #9b9eac;
    padding: 10px;
  }
  tr > td {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 10px;
    color: #20253a;
  }
  tr > th,
  tr > td,
  .ant-table-container {
    border-color: #d9d9d9 !important;
    word-wrap: break-word;
    word-break: break-all;
  }
  .grey {
    background: #fafafa;
  }
  .cursor {
    cursor: pointer;
  }
  .ant-table-column-sorters {
    padding: 10px;
  }
`
