import moment from 'moment'

import { COLLECTION_METHOD_OPTIONS } from '@/views/pcenter/common/constants'
import { WITHDRAWAL_STATUS } from './constants'

import { showCurrentLabel } from '@/utils/gadget'
interface ColumnItem {
  title: string
  dataIndex: string
  render?: any
  align?: 'center'
}

export const getWithdrawalColumns = () => {
  let columns: ColumnItem[] = [
    {
      title: '订单类型',
      dataIndex: 'type',
    },
    {
      title: '订单生成时间',
      dataIndex: 'create',
      render: (time: number) => {
        return moment(time * 1000).format('YYYY/MM/DD')
      },
    },
    {
      title: '订单编号',
      dataIndex: 'txId',
    },
    {
      title: '提现金额',
      dataIndex: 'amount',
      render: (amount: number) => {
        return Number((amount / 100).toFixed(2))
      },
    },
    {
      title: '收款方式',
      dataIndex: 'collectionMethod',
      render: (type: string) => {
        return showCurrentLabel(COLLECTION_METHOD_OPTIONS, type)
      },
    },
    {
      title: '收款账号',
      dataIndex: 'collectionNumber',
    },
    {
      title: '订单状态',
      dataIndex: 'status',
      render: (type: string) => {
        return showCurrentLabel(WITHDRAWAL_STATUS, type)
      },
    },
  ]
  columns.forEach((item: ColumnItem) => (item.align = 'center'))
  return columns
}

export const getEntryColumns = () => {
  let columns: ColumnItem[] = [
    {
      title: '账单类型',
      dataIndex: 'type',
    },
    {
      title: '账单生成时间',
      dataIndex: 'create',
      render: (time: number) => {
        return moment(time * 1000).format('YYYY/MM/DD')
      },
    },
    {
      title: '账单编号',
      dataIndex: 'rzId',
    },
    {
      title: '入账金额',
      dataIndex: 'amount',
      render: (amount: number) => {
        return Number((amount / 100).toFixed(2))
      },
    },
    {
      title: '订单状态',
      dataIndex: 'walletType',
      render: (type: number) => {
        return `已放入「我的${type === 0 ? '月结' : '周结'}钱包」`
      },
    },
  ]
  columns.forEach((item: ColumnItem) => (item.align = 'center'))
  return columns
}
