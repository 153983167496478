import React, { useState } from 'react'

import { List, Button, Skeleton, Modal, Tabs } from 'antd'

import styled from 'styled-components'
import { getMessageDetail } from '@/api/message'
import moment from 'moment'

import HighIcon from '@/assets/images/重要@2x.png'
import MiddleIcon from '@/assets/images/一般通知@2x.png'
import LowIcon from '@/assets/images/不重要@2x.png'

const { TabPane } = Tabs

const BgWrapper = styled.div`
  background: #fff;
  width: 300px;
  padding: 0;
  min-height: 200px;
  box-sizing: border-box;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;

    .container{
        max-height: 250px;
        overflow-x: auto;
    }

    .icon{
        font-size: 18px;
        font-weight: 600;
    }

    .line-item{
        line-heightL 1.2;
        color: #333;
        cursor: pointer;

        .time{
            font-size: 12px;
            color: #666;
        }
    }
`

const getUrl = (type: '高' | '中' | '低') => {
  switch (type) {
    case '高':
      return HighIcon
    case '中':
      return MiddleIcon
    case '低':
      return LowIcon
    default:
      return LowIcon
  }
}

function NoticeList({
  setNoticeType,
  loadMore,
  total,
  loading,
  noticeList,
  refresh,
  type,
  noReadCount,
}: {
  setNoticeType: React.Dispatch<React.SetStateAction<string>>
  loadMore: () => void
  loading: boolean
  total: number
  noticeList: any[]
  refresh: (type: 'unread' | 'all' | string) => void
  noReadCount: number
  type: 'unread' | 'all' | string
}) {
  const [visible, setVisible] = useState(false)
  const [detail, setDetail] = useState<Record<string, any>>({})

  return (
    <BgWrapper>
      <Modal
        visible={visible}
        title={detail.title}
        width={560}
        maskClosable={true}
        onCancel={() => {
          setVisible(false)
        }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setVisible(false)
              }}
            >
              我知道了
            </Button>
          </div>
        }
      >
        <div>
          <div>
            <Decoder content={detail.content}></Decoder>
          </div>
          <p
            style={{
              marginTop: '20px',
              color: '#999',
              textAlign: 'right',
            }}
          >
            {moment(detail.sendTime).format('YYYY-MM-DD HH:mm:ss')}
          </p>
        </div>
      </Modal>
      <Tabs
        defaultActiveKey="unread"
        onChange={(key) => {
          setNoticeType(key)
          refresh(key)
        }}
      >
        <TabPane
          key="unread"
          tab={
            <div
              style={{
                width: '132px',
                textAlign: 'center',
              }}
            >
              未读 ({noReadCount})
            </div>
          }
        ></TabPane>
        <TabPane
          key="all"
          tab={
            <div
              style={{
                width: '132px',
                textAlign: 'center',
              }}
            >
              全部
            </div>
          }
        ></TabPane>
      </Tabs>
      <div className="container">
        <List
          itemLayout="horizontal"
          dataSource={noticeList}
          size="small"
          loadMore={
            !loading && noticeList.length < total ? (
              <div>
                <Button
                  onClick={loadMore}
                  type="dashed"
                  style={{
                    display: 'block',
                    width: '100%',
                  }}
                >
                  加载更多...
                </Button>
              </div>
            ) : null
          }
          renderItem={(item) => (
            <List.Item>
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={
                    <div
                      style={{
                        paddingTop: '4px',
                      }}
                    >
                      <img
                        src={getUrl(item.level)}
                        alt=""
                        style={{
                          width: '30px',
                          height: '30px',
                        }}
                      />
                    </div>
                  }
                  title={
                    <div
                      className="line-item"
                      onClick={async () => {
                        const data = await getMessageDetail(item.id)
                        if (data) {
                          setDetail(data)
                          setVisible(true)
                          refresh(type)
                        }
                      }}
                    >
                      <div className="title">{item.title}</div>
                      <div className="time">{item.sendTime}</div>
                    </div>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
    </BgWrapper>
  )
}

export default NoticeList

function Decoder({ content }: { content: string }) {
  const reg = /\[([^\]]+)\]\((https:\/\/[^\)]+)\)/g
  const symbol = '22eewdsdsfsdf'
  const lineBreak = /\r?\n/
  const arr = content.split(lineBreak).map((line) => {
    return line
      .replace(reg, function (_, $1, $2) {
        console.log($1, $2)
        return `${symbol}${JSON.stringify({
          link: $2,
          text: $1,
        })}${symbol}`
      })
      .split(`${symbol}`)
      .map((i) => {
        if (i.startsWith('{') && i.endsWith('}')) {
          return JSON.parse(i)
        }
        return i
      })
  })
  return (
    <div>
      {arr.map((line, index) => {
        return (
          <div key={index}>
            {line.map((i) => {
              if (typeof i === 'object') {
                return (
                  <a href={i.link} target="__blank">
                    {i.text}
                  </a>
                )
              } else {
                return i
              }
            })}
          </div>
        )
      })}
    </div>
  )
}
