import React, { ReactNode } from 'react'
import { Route } from 'react-router-dom'
import RightBar from '@/components/RightBar'

export function MyRoute({
  path,
  exact,
  children,
}: {
  path: string | string[]
  exact?: boolean
  children?: ReactNode
}) {
  return (
    <Route path={path} exact={exact}>
      <div>
        <div>{children}</div>
        <RightBar />
      </div>
    </Route>
  )
}
