import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const IconTooltip: React.FC<{
  text: string;
  tooltip?: string | React.ReactNode;
}> = ({ text, tooltip }) => {
  return (
    <>
      <span>{text}</span>
      {tooltip && (
        <>
          <span>&nbsp;</span>
          <Tooltip title={tooltip}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      )}
    </>
  );
};

export default IconTooltip;
