import {
  axiosGet,
  axiosPost,
  axiosSffGet,
  axiosAccountPost,
} from '../utils/request'
import { getAccountInfo } from '@/utils/api'
import { store } from '@/route/App'
import { SET_PAYINFO } from '@/redux/user/types'
export interface CertiInfo {
  IDCardNumber: string
  realName: string
}

export interface PaymentInfo {
  bankAccount: string
  bankName: string
  cardNumber: string
  collectionMethod?: 'personalAlipay' | 'personalCard' | 'enterpriseCard'
  collectionNumber: string
  invoiceType: 'specialSix' | 'specialThreeOrOne' | 'plain'
  verifyCode: string
  phoneNumber: string
  billingCycle: string
}

export type PayInfo = Omit<PaymentInfo, 'verifyCode'>
//实名认证
export const certification = (params: CertiInfo) => {
  return axiosAccountPost('/updateRealNameInfo', params).then((res) => {
    getAccountInfo()
    return res
  })
}
//发送验证码修改账户信息
export const sendCaptchar = (phoneNumber: string) => {
  return axiosPost('/user/send_verify_code', {
    phoneNumber,
    usage: 2,
  }).then((res) => res.data)
}

//获取付款信息
export const getPayInfo: () => Promise<PayInfo> = () => {
  return axiosGet('/user/account_payinfo').then((res) => {
    store.dispatch({
      type: SET_PAYINFO,
      payInfo: res.data,
    })
    return res.data
  })
}
//更新付款信息
export const updatePaymentInfo = (params: PaymentInfo) => {
  return axiosPost('/user/modify_account_payinfo', params).then((res) => {
    getAccountInfo()
    getPayInfo()
    return res.data
  })
}

//换绑手机号之
//验证旧手机号
export const verifyPreCode = (params: {
  phoneNumber: string
  verifyCode: string
}) => {
  return axiosPost('/user/verify_verification_code', params).then(
    (res) => res.data,
  )
}
//验证新手机号
export const verifyNewCode = (params: {
  phoneNumber: string
  verifyCode: string
  ticket: string
}) => {
  return axiosPost('/user/modify_phone_number', params).then((res) => {
    getAccountInfo()
    getPayInfo()
    return res.data
  })
}

//修改或设置钱包密码
export const modifyWalletPassword = (params: {
  password: string
  verifyCode: string
}) => {
  return axiosPost('/user/modify_wallet_password', params).then((res) => {
    getAccountInfo()
    return res.data
  })
}
//验证钱包密码
export const verifyWalletPassword = (params: { password: string }) => {
  return axiosPost('/user/verify_wallet_password', params).then((res) => {
    return res.data
  })
}
//修改结算周期
export type BillingCycleType = 'week' | 'month' | ''
export const modifyBillingCycle = (params: {
  billingCycle: BillingCycleType
}) => {
  return axiosPost('/user/modify_billing_cycle', params).then((res) => {
    getAccountInfo()
    getPayInfo()
    return res.data
  })
}
// 获取token
export function getMiniappAccessToken() {
  return axiosSffGet('/urlScheme').then((res) => {
    return res.data
  })
}

export const logoutPrint = () => {
  return axiosPost('/user/logout')
}
