import { Form, Input } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import { $Link_COLOR } from '@/constants/styles'
import { sendCaptchar } from '@/api/user'
import doCountdown from '@/utils/countdown'

const PhoneItem = styled(Form.Item)`
  margin-bottom: 4px;
  .ant-form-item-control {
    justify-content: center;
  }
  .ant-form-item-control-input {
    min-height: 14px;
    height: 14px;
    line-height: 14px;
  }
  .ant-form-item-label > label {
    line-height: 14px;
    height: 14px;
  }
`
const CodeItem = styled(Form.Item)`
  .ant-form-item-label > label::before {
    display: none !important;
  }
`
export default (props: { phoneNumber: string }) => {
  const [confirmDeadline, setConfirm] = useState(0)
  return (
    <>
      <PhoneItem label="手机号：">
        <Form.Item noStyle name="phoneNumber" style={{ width: '264px' }}>
          <div style={{ marginRight: '108px', display: 'inline-block' }}>
            {props.phoneNumber}
          </div>
        </Form.Item>
        {!confirmDeadline && (
          <span
            style={{
              marginLeft: 'auto',
              color: $Link_COLOR,
              cursor: 'pointer',
            }}
            onClick={() =>
              sendCaptchar(props.phoneNumber).then(() => {
                doCountdown({
                  deadline: 60,
                  setNumber: setConfirm,
                  callback: () => {},
                })
              })
            }
          >
            发送验证码
          </span>
        )}
        {!!confirmDeadline && (
          <span
            style={{
              marginLeft: 'auto',
              color: '#666',
              cursor: 'not-allowed',
            }}
          >
            {confirmDeadline}秒后重试
          </span>
        )}
      </PhoneItem>
      <CodeItem
        label="验证码："
        name="verifyCode"
        rules={[
          {
            required: true,
            message: '验证码不能为空',
          },
          {
            len: 6,
            message: '请输入6位验证码',
          },
        ]}
      >
        <Input style={{ width: '264px' }} placeholder="请输入6位验证码" />
      </CodeItem>
    </>
  )
}
