import { useGetDialLineQuery, DialItem } from '../manageLineSlice'
import { useGlobalContext } from '@/route/globalContext'
export default function useQueryData() {
  const { manageLineData, setManageLineData } = useGlobalContext()

  const { data, ...rest } = useGetDialLineQuery(manageLineData.deviceUUID, {
    skip: !manageLineData.deviceUUID,
  })
  const hasBinded = data?.dialList.some((i) => i.isManager) ?? false
  return {
    manageLineData,
    setManageLineData,
    data: { dialList: [], ...data },
    hasBinded,
    ...rest,
  }
}
