import NoticeModal from '@/components/NoticeModal'
import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { useAppSelector } from '@/redux'
const WarnSPan = styled.span`
  color: ${(props) => props.theme.errorColor};
`

export default function NodeInfoCollectionModal() {
  const { username } = useAppSelector((state) => state.user)
  const formHref =
    window.location.origin + '/external-form/node-address?username=' + username
  return (
    <NoticeModal title="提示" visible={true} closable={false}>
      <div>
        为了确保您能正常<WarnSPan>上机、计费及提现</WarnSPan>
        ，请完善您的节点信息！
      </div>

      <div
        style={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#EAEBEB',
          margin: '20px 0',
        }}
      >
        <div style={{ marginBottom: '5px' }}>点击链接完善节点信息</div>
        <div>
          <a href={formHref} target="_blank" rel="noopener noreferrer">
            {formHref}
          </a>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{ marginBottom: '10px' }}>
          提交表单后点击“我已提交”进行刷新
        </div>
        <Button
          type="primary"
          onClick={() => {
            window.location.reload()
          }}
        >
          我已提交
        </Button>
      </div>
    </NoticeModal>
  )
}
