import React, { useState } from 'react'
import NoticeModal from '@/components/NoticeModal'
import { useHistory } from 'react-router-dom'
import { Input, Col, Row, message, Button } from 'antd'
import cookies from 'js-cookie'

import { ModalType } from '..'
import { verifyWalletPassword } from '@/api/user'

export default (props: {
  type: ModalType
  setModal: any
  username: string
}) => {
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  return (
    <NoticeModal title="" visible={!!props.type} closable={false}>
      <Row style={{ alignItems: 'center' }}>
        <Col offset={4} style={{ textAlign: 'right' }} span={6}>
          请输入钱包密码：
        </Col>
        <Col span={10}>
          <Input
            type="password"
            onChange={(e) => {
              e.persist()
              setPassword(e.target.value)
            }}
            value={password}
            placeholder="请输入6~12位钱包密码"
          ></Input>
        </Col>
      </Row>
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}
      >
        <Button onClick={history.goBack}>取消</Button>
        <Button
          style={{ marginLeft: '20px' }}
          type="primary"
          loading={loading}
          disabled={password.length < 6 || password.length > 12}
          onClick={() => {
            setLoading(true)
            verifyWalletPassword({ password })
              .then((res) => {
                //用来计时24小时内过期
                cookies.set(
                  `ticket-${props.username}`,
                  res.ticket +
                    ':Expires=' +
                    new Date().setDate(new Date().getDate() + 1),
                  {
                    expires: 1,
                  },
                )
                //用来保证关页面消失
                message.success('验证成功')
                props.setModal('')
              })
              .catch(() => {
                setLoading(false)
              })
          }}
        >
          确定
        </Button>
      </div>
      <div style={{ textAlign: 'right', color: '#aaa' }}>
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.push('/user/password?type=wallet')
          }}
        >
          忘记密码
        </span>
      </div>
    </NoticeModal>
  )
}
