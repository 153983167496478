import React, { useRef } from 'react'
import { Select, Form, Spin } from 'antd'
import { useSearchBankMutation, Bank } from './infoSlice'
import { filter, distinctUntilChanged, switchMap, tap } from 'rxjs/operators'
import { from } from 'rxjs'
import { useObservableState } from 'observable-hooks'

export default function SearchAddress() {
  const [search, { isLoading }] = useSearchBankMutation()
  const optionsRef = useRef<null | Bank[]>(null)

  const [_, setText] = useObservableState<Bank[], string>((e) =>
    e.pipe(
      tap(() => {
        optionsRef.current = []
      }),
      filter((val) => {
        return val.length > 0
      }),
      distinctUntilChanged(),
      switchMap((val) =>
        from(
          search(val)
            .unwrap()
            .then((res) => {
              optionsRef.current = res
              return res
            }),
        ),
      ),
    ),
  )
  return (
    <Form.Item
      name="bankName"
      label="开户行"
      rules={[{ required: true, message: '请输入开户行' }]}
    >
      <Select
        showSearch
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={(newValue: string) => {
          setText(newValue)
        }}
        placeholder="请输入开户行"
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        options={optionsRef.current?.map((i) => {
          return {
            label: i.fName,
            value: i.fName,
          }
        })}
      ></Select>
    </Form.Item>
  )
}
