export const $SUCCESS_COLOR = '#27ae60'
export const $ERROR_COLOR = '#fa5555'
export const $Link_COLOR = '#2E56FF'
export const $WARN_COLOR = '#F58021'
export const Color = {
  Primary: '#2e5bff',
  MajorText: '#414141',
  SubText: '#9b9eac',
  Error: '#fa5555',
  Success: '#27ae60',
  SuccessLight: '#00C853',
  Warn: '#F58021',
  Border: '#d9d9d9',
  Background: '#f5f5f5',
  Title: '#1D1D1D',
}
