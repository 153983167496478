import { getWithdrawalColumns, getEntryColumns } from '../common/columns'
import {
  useGetEntryRecordQuery,
  useGetWithdrawalRecordQuery,
  initialRecord,
} from '../walletSlice'

type BillType = 'weekly' | 'monthly'
type TableType = 'entry' | 'withdraw'
export type RecordType = `${BillType}-${TableType}`

type Config = {
  [key in RecordType]: {
    title: string
    columns: any
    rowKey: string
    //这个hook暂时不知道怎么表示，UseQuery没法引入
    fetchHook: any
    initialData: any
    walletType: 0 | 1
  }
}

const recordConfig: Config = {
  'monthly-entry': {
    fetchHook: useGetEntryRecordQuery,
    title: '放入钱包记录',
    columns: getEntryColumns(),
    rowKey: 'rzId',
    initialData: initialRecord,
    walletType: 0,
  },
  'monthly-withdraw': {
    fetchHook: useGetWithdrawalRecordQuery,
    title: '月结提现记录',
    columns: getWithdrawalColumns(),
    rowKey: 'txId',
    initialData: initialRecord,
    walletType: 0,
  },
  'weekly-entry': {
    fetchHook: useGetEntryRecordQuery,
    title: '放入钱包记录',
    columns: getEntryColumns(),
    rowKey: 'rzId',
    initialData: initialRecord,
    walletType: 1,
  },
  'weekly-withdraw': {
    title: '周结提现记录',
    fetchHook: useGetWithdrawalRecordQuery,
    columns: getWithdrawalColumns(),
    rowKey: 'txId',
    initialData: initialRecord,
    walletType: 1,
  },
}
export default recordConfig
