import React, { useEffect, useState } from 'react'
import {  useLocation } from 'react-router-dom'
import { Popover } from 'antd'
import {  WechatOutlined } from '@ant-design/icons'
import Logo from '@/assets/images/公众号二维码.jpg'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  right: 10px;
  bottom: 200px;
  z-index: 110;

  ul {
    width: 80px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #2e5bff;
    border-top: 5px solid #2e5bff;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  }

  li {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100px;
    color: #1f83ff;
    align-item: center;
    justify-content: center;

    .icon {
      font-size: 30px;
    }
  }
`
function RightBar() {
  const location = useLocation()
  const [showServiceBar, setShowServiceBar] = useState(false)
  useEffect(() => {
    const service_white_list = [
      '/index',
      '/welcome',
      '/vip-recruit',
      '/help-center',
      '/dashboard/bill',
      '/help/bind_device',
    ]

    const path = location.pathname

    if (service_white_list.includes(path)) {
      setShowServiceBar(true)
    } else {
      setShowServiceBar(false)
    }
  }, [location])

  return showServiceBar ? (
    <Wrapper>
      <ul>
        <li>
          <Popover
            placement="left"
            title={null}
            content={
              <img
                style={{
                  width: '160px',
                  height: '160px',
                }}
                src={Logo}
                alt=""
              />
            }
          >
            <div className="icon">
              <WechatOutlined />
            </div>
            <div>公众号</div>
          </Popover>
        </li>
      </ul>
    </Wrapper>
  ) : null
}

export default RightBar
