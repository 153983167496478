export const WITHDRAWAL_STATUS = [
  {
    label: '提现中',
    value: 'transferring',
  },
  {
    label: '提现失败',
    value: 'failure',
  },
  {
    label: '提现成功',
    value: 'success',
  },
]
