import Axios, { AxiosRequestConfig } from 'axios'
import { payApiBaseurl } from '../config'

export const AxiosCostomInstance = Axios.create() //用自定义错误码的进行处理

AxiosCostomInstance.interceptors.request.use(
  (config: any) => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    return config
  },
  (error: any) => Promise.reject(error),
)
AxiosCostomInstance.defaults.baseURL = payApiBaseurl

export const getViolationRecord = (date: string) =>
  AxiosCostomInstance({
    url: `v1/device/violation`,
    params: {
      date,
    },
  }).then((res) => res.data)

export const axiosPost: <T = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig | undefined,
) => Promise<T> = (url, data, config) => {
  return AxiosCostomInstance.post(`/v1${url}`, data, config).then(
    (res) => res.data,
  )
}

// 这里的返回值被Authorized里面修改了，因此需要修改定义
export const axiosGet: <T = any>(
  url: string,
  config?: AxiosRequestConfig | undefined,
) => Promise<T> = (url, config) => {
  return AxiosCostomInstance.get(`/v1${url}`, config).then((res) => res.data)
}
