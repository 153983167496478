import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Input, Button, message } from 'antd'

import { $Link_COLOR } from '@/constants/styles'
import { modifyWalletPassword, sendCaptchar } from '@/api/user'
import doCountdown from '@/utils/countdown'

export const PasswordForm = styled(Form)`
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
`

export default (props: { phoneNumber: number }) => {
  const [form] = Form.useForm()
  const [confirmDeadline, setConfirm] = useState(0)
  const [loading, setLoading] = useState(false)
  const initialValues = {
    phoneNumber: props.phoneNumber,
    verifyCode: '',
    password: '',
    newPassword: '',
  }
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true)
        delete values.newPassword
        delete values.phoneNumber
        modifyWalletPassword(
          values as { password: string; verifyCode: string },
        ).then((res) => {
          message.success('设置成功')
          form.resetFields()
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <PasswordForm
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      labelAlign="left"
      form={form}
    >
      <Form.Item
        label="输入钱包密码："
        name="password"
        rules={[
          {
            required: true,
            message: '请输入钱包密码',
          },
          {
            min: 6,
            max: 12,
            message: '请输入6~12位钱包密码',
          },
        ]}
      >
        <Input
          type="password"
          placeholder="请输入6~12位钱包密码，可以包含数字、字母、特殊字符"
        ></Input>
      </Form.Item>
      <Form.Item
        label="再次输入密码："
        name="newPassword"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: '请再次输入以确认钱包密码',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject('两次输入不相同')
            },
          }),
        ]}
      >
        <Input type="password" placeholder="请再次输入钱包密码"></Input>
      </Form.Item>
      <Form.Item label="手机号：">
        <Form.Item noStyle name="phoneNumber">
          <Input readOnly style={{ width: '70%', border: 'none' }}></Input>
        </Form.Item>
        {!confirmDeadline && (
          <span
            style={{
              marginLeft: 'auto',
              color: $Link_COLOR,
              cursor: 'pointer',
            }}
            onClick={() =>
              sendCaptchar(form.getFieldValue('phoneNumber')).then(() => {
                doCountdown({
                  deadline: 60,
                  setNumber: setConfirm,
                  callback: () => {},
                })
              })
            }
          >
            发送验证码
          </span>
        )}
        {!!confirmDeadline && (
          <span
            style={{
              marginLeft: 'auto',
              color: '#666',
              cursor: 'not-allowed',
            }}
          >
            {confirmDeadline}秒后重试
          </span>
        )}
      </Form.Item>
      <Form.Item
        label="验证码："
        name="verifyCode"
        rules={[
          { required: true, message: '请输入验证码' },
          {
            len: 6,
            message: '请输入六位验证码',
          },
        ]}
      >
        <Input placeholder="请输入6位验证码" />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }}>
        <Button
          block
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          提交
        </Button>
      </Form.Item>
    </PasswordForm>
  )
}
