import { axiosNoticeGet } from '../utils/request'

export function getMessageList(params: {
  type: 'unread' | 'all' | string
  pageIndex: number
  pageSize: number
}): Promise<{
  total: number
  list: { id: string; title: string; level: string; sendTime: string }[]
}> {
  return axiosNoticeGet('/list', { params })
    .then((data) => data.data)
    .catch((err) => err)
}

export function getMessageDetail(id: string): Promise<{
  id: string
  title: string
  content: string
  sendTime: string
  message: String
}> {
  const params = { id }
  return axiosNoticeGet(`/${id}`, { params })
    .then((data) => data.data)
}
