import apiSlice from '@/api/apiSlice'
import { DiallingType } from '../types'

export interface DialLineResponse {
  /**
   * 是否版本一致
   */
  diallingInfoVersionConsistent: boolean
  /**
   * 拨号状态  仅有dialling和dialled两个值
   */
  dialStatus: 'dialling' | 'dialled'
  /**
   * 拨号类型
   */
  dialType: DiallingType
  /**
   * 剩余拨号次数
   */
  remainModifyCount: number
  dialList: DialItem[]
  dialInfo: Record<string, DialItem>
}

export interface DialItem {
  ip: string
  isManager: boolean
  speed: number
  name: string
}

interface BindBody {
  card?: string
  deviceUUID: string
  unConfirm?: true //是不是解除管理网卡
}

const slice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getDialLine: build.query<DialLineResponse, string>({
      query: (deviceUUID) => {
        return {
          url: '/v1/miner/dialling/info/v2',
          params: { deviceUUID },
        }
      },
      transformResponse: (res: DialLineResponse) => {
        const list = Object.keys(res.dialInfo).map((i) => {
          return { ...res.dialInfo[i], name: i }
        })
        return { ...res, dialList: list }
      },
      providesTags: ['DialList'],
    }),
    bindManageLine: build.mutation<void, BindBody>({
      query: (data) => {
        return {
          url: '/v1/miner/dialling/confirm_manager_network_card',
          data,
          method: 'POST',
        }
      },
      invalidatesTags: ['DialList'],
    }),
  }),
})
export const { useGetDialLineQuery, useBindManageLineMutation } = slice
