export const INVOICE_TYPES_OPTIONS = [
  { label: '增值税专用发票6%税点', value: 'specialSix' },
  { label: '增值税专用发票3%或1%税点', value: 'specialThreeOrOne' },
  { label: '普通发票', value: 'plain' },
]
export type collectionMethodType =
  | 'personalAlipay'
  | 'personalCard'
  | 'enterpriseCard'
export const COLLECTION_METHOD_OPTIONS: {
  label: string
  value: collectionMethodType
}[] = [
  { label: '个人支付宝', value: 'personalAlipay' },
  { label: '个人银行卡', value: 'personalCard' },
  { label: '企业银行账户', value: 'enterpriseCard' },
]
