interface Captchar {
  deadline: number
  setNumber: (time: number) => void
  callback: () => void
}

export default (params: Captchar) => {
  let countDown = (deadline: number) => {
    if (deadline < 1) {
      params.callback()
      return
    }
    setTimeout(() => {
      deadline--
      params.setNumber(deadline)
      countDown(deadline)
    }, 1000)
  }
  params.setNumber(params.deadline)
  countDown(params.deadline)
}
