import { createContext, useContext, useState } from 'react'

interface ManageLineDataType {
  deviceUUID: string
  onManageChange: () => void
}
interface GlobalContainType {
  manageLineData: ManageLineDataType
  setManageLineData: React.Dispatch<React.SetStateAction<ManageLineDataType>>
}

const intialGlobalData: GlobalContainType = {
  manageLineData: {
    deviceUUID: '',
    onManageChange: () => {},
  },
  setManageLineData: () => {},
}

export const GlobalContainContext = createContext(intialGlobalData)

export const useGlobalContext = () => useContext(GlobalContainContext)

export function useGlobalData() {
  const [manageLineData, setManageLineData] = useState(
    intialGlobalData.manageLineData,
  )
  return {
    manageLineData,
    setManageLineData,
  }
}
