import React, { useEffect } from 'react'
import { isPainet } from './distinguishDomain'
export default function initMetaInfo() {
  if (!isPainet()) {
    const painetname = '派享云'
    const name = '启派云智网站'
    document.title = document.title.replace(painetname, name)
    const description = document.querySelector('meta[name="description"]')
    if (description) {
      description.setAttribute(
        'content',
        description.getAttribute('content')?.replace(painetname, name) ?? '',
      )
    }
  }
}
