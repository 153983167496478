import { axiosGet as axiosPayGet } from '@/utils/services'
import { updateWithdrawParamsAction } from '@/redux/withdraw'
import { store } from '@/route/App'

export const getWithdrawParams = () => {
  return axiosPayGet('/settled_param').then((res) => {
    if (res.data && Array.isArray(res.data?.params)) {
      store.dispatch(updateWithdrawParamsAction(res.data.params))
    }
  })
}
