import NoticeModal from '@/components/NoticeModal'
import { $ERROR_COLOR } from '@/constants/styles'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, message, Radio } from 'antd'
import styled from 'styled-components'
import { AppState } from '../redux'
import { logout } from '../redux/user/actions'
import { UserInfo } from '../redux/user/types'
import BillNoticeModal from '../views/dashboard/components/BillNoticeModal' //用来标记是否弹窗过sla，由于历史原因命名和sla没关系，但为了不影响现有用户就不改了
import { WeeklyText } from '@/views/wallet/components/weekly/Desc'
import { modifyBillingCycle, BillingCycleType } from '@/api/user'
export const NOTICE_STATUS: string = 'noticeConfirmed'
const NoticeUl = styled.ul`
  li {
    text-indent: 2em;
  }
  span {
    color: #fa5555;
  }
`
export type noticeType = '' | 'scanToMiniapp' | 'sla' | 'weekly-settlement'
//分别表示不弹窗，去认证，弹sla,弹周结
export type SetNotice = React.Dispatch<React.SetStateAction<noticeType>>

export const SlaModal = (props: {
  visible: boolean
  recheckModalStatus: () => void
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  return (
    <BillNoticeModal
      onOk={() => {
        localStorage.setItem(NOTICE_STATUS, 'true')
        props.recheckModalStatus()
      }}
      onCancel={() => {
        dispatch(logout())
        history.push('/login')
      }}
      closable={false}
      title="违规扣费通知"
      visible={props.visible}
      cancelPopoverContent="不同意该扣费协议会退出登录，如有异议请与派享云官方协商（微信：zengqian-7548）"
    >
      <div style={{ color: '#333', lineHeight: '1.5' }}>
        <p style={{ marginBottom: '10px' }}>亲爱的用户：</p>
        <p style={{ textIndent: '2em' }}>
          你好，经过慎重考虑和调研，为了保证业务的顺利进行，我们决定对设备服务质量做出以下要求：
        </p>
        <NoticeUl>
          <li>
            1.当日出现1次机器故障，<span>当日不计费</span>
            (高峰期10-14点超过15分钟或当日18-24点间机器超过5分钟未服务视为机器故障)
          </li>
          <li>
            2.当日机器服务时间小于12小时，<span>当日不计费</span>
          </li>
          <li>
            3. 买断计费设备，当日掉线路，<span>扣除该线路掉线时段收益</span>
          </li>
          <li>
            4. 所有设备，当日晚高峰掉线路持续30min，
            <span>扣除该线路当日收益</span>
          </li>
          <li>
            5. 长A设备当月连续服务不足15日或累计故障达5日，
            <span>当月不计费</span>
          </li>
          <li>
            6. 当日出现限速、虚报、共享带宽等情况，<span>当日不计费</span>
          </li>
          <li>
            7. 触发多条sla，<span>不重复扣罚</span>
          </li>
        </NoticeUl>
        <p style={{ textAlign: 'right', marginTop: '5px' }}>派享云 </p>
        {/* <p style={{ textAlign: 'right' }}>2020/06/11</p> */}
      </div>
    </BillNoticeModal>
  )
}

export const ConfirmBillModal = (props: {
  visible: boolean
  setNoticeStatus: SetNotice
}) => {
  const history = useHistory()
  const { idenAuthenticationed } = useSelector<AppState, UserInfo>(
    (state) => state.user,
  )
  return (
    <NoticeModal
      title=""
      visible={props.visible}
      closable={false}
      okText={idenAuthenticationed ? '填写收款信息' : '实名认证'}
      onOk={() => {
        props.setNoticeStatus('')
        history.push(
          idenAuthenticationed ? '/user/beneficiary' : '/user/certification',
        )
      }}
    >
      <div style={{ textAlign: 'center' }}>
        为了确保您能正常获得收益，请进行
        <span style={{ color: $ERROR_COLOR }}>实名认证</span>，并填写您的
        <span style={{ color: $ERROR_COLOR }}>收款信息</span>哦！
      </div>
    </NoticeModal>
  )
}

export function WeeklySettlement(props: {
  visible: boolean
  setNoticeStatus: SetNotice
}) {
  const history = useHistory()

  const [selectType, onRadioChange] = useState<BillingCycleType>('')
  const confirm = useCallback(() => {
    modifyBillingCycle({ billingCycle: selectType }).then(() => {
      props.setNoticeStatus('')
      message.success('提交成功')
      if (selectType === 'week') {
        history.push('/wallet')
      }
    })
  }, [history, props, selectType])
  return (
    <NoticeModal title="重要通知" visible={props.visible} closable={false}>
      <div style={{ fontSize: '16px' }}>
        为了回馈广大派享云用户，平台特推出周结模式，您可自行选择周结/月结！
      </div>
      <div
        style={{
          backgroundColor: '#F5F5F5',
          padding: '12px',
          margin: '8px 0',
        }}
      >
        <WeeklyText></WeeklyText>
      </div>
      <div style={{ fontSize: '16px', marginBottom: '14px' }}>
        您是否要选择周结算呢？
      </div>
      <Radio.Group
        onChange={(e) => {
          onRadioChange(e.target.value)
        }}
        value={selectType}
      >
        <Radio value="week">周结，并同意周结规则（见上述）</Radio>
        <Radio value="month">月结</Radio>
      </Radio.Group>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button type="primary" disabled={!selectType} onClick={confirm}>
          提交
        </Button>
      </div>
    </NoticeModal>
  )
}
