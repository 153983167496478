type domainType = 'pyinfra' | 'painet'

export default function distinguishDomain(): domainType {
  const hostname = window.location.hostname
  if (hostname.includes('pyinfra')) {
    return 'pyinfra'
  }
  return 'painet'
}

export function isPainet() {
  return distinguishDomain() === 'painet'
}
