import React from 'react'

import NoticeModal from '@/components/NoticeModal'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { ModalType } from '..'

const Content = styled.div`
  text-align: center;
  b {
    font-size: 1.3em;
    color: #333;
  }
`

export default (props: { type: ModalType; onCancel: any }) => {
  const history = useHistory()

  switch (props.type) {
    case 'requiredPassword':
      return (
        <NoticeModal
          closable={false}
          width={360}
          okText="前往设置"
          onCancel={history.goBack}
          cancelText="暂不设置"
          onOk={() => {
            history.push('/user/password?type=wallet')
          }}
          title="提示"
          visible={!!props.type}
        >
          <Content>
            您还未设置<b>钱包</b>密码
          </Content>
        </NoticeModal>
      )
    case 'requiredIdentify':
      return (
        <NoticeModal
          width={360}
          closable={false}
          okText="前往认证"
          onCancel={() => props.onCancel('')}
          onOk={() => history.push('/user/certification')}
          cancelText="暂不认证"
          title="提示"
          visible={!!props.type}
        >
          <Content>您还未进行实名认证</Content>
        </NoticeModal>
      )
    case 'requiredCollectionInfo':
      return (
        <NoticeModal
          width={360}
          closable={false}
          okText="添加收款信息"
          onCancel={() => props.onCancel('')}
          onOk={() => history.push('/user/beneficiary')}
          cancelText="暂不添加"
          title="提示"
          visible={!!props.type}
        >
          <Content>您还未进行实名认证</Content>
        </NoticeModal>
      )
    default:
      return null
  }
}
