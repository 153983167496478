import { message } from 'antd'
import moment from 'moment'
export function convertFieldInArrayObject<T extends object, K extends keyof T>(
  arr: T[] | undefined,
  fieldCallback: SingleOrArray<{
    field: SingleOrArray<K>
    callback: (value: any) => any
  }>,
): T[] {
  if (!arr) return []
  if (!(fieldCallback instanceof Array)) {
    fieldCallback = [fieldCallback]
  }
  const fieldCallbackMap: any = {}
  fieldCallback.forEach(({ field, callback }) => {
    if (!(field instanceof Array)) {
      field = [field]
    }
    field.forEach((f) => {
      fieldCallbackMap[f] = callback
    })
  })
  return arr.map((i) => {
    const newObj: any = Object.assign({}, i)
    for (let field in fieldCallbackMap) {
      if (field in newObj) {
        newObj[field] = fieldCallbackMap[field](newObj[field])
      }
    }
    return newObj
  })
}

export const getYuanFromPoint = (n: number) => n / 100

export const commonErrorHandler = (e: Error | string | any) => {
  if (e instanceof Error) {
    message.error(e.message)
  } else if (typeof e === 'string') {
    message.error(e)
  } else {
    try {
      message.error(JSON.stringify(e))
    } catch {
      console.error(e)
      message.error('未知错误')
    }
  }
}
export const formatUnixTime = (
  time: number,
  format = 'YYYY-MM-DD HH:mm:ss',
): string =>
  time === 0 || time == null ? '' : moment(time * 1000).format(format)
