import { updateBillStatus } from '@/redux/bill/reducer'
import { setAccoutInfo } from '@/redux/user/actions'
import { store } from '@/route/App'
import { message } from 'antd'
import moment from 'moment'
import qs, { ParsedUrlQueryInput } from 'querystring'
import { apiBaseurl } from '../config'
import {
  convertFieldInArrayObject,
  getYuanFromPoint,
  formatUnixTime,
} from './chore'
import { exportJSON } from './excel'
import {
  axiosDelete,
  axiosGet,
  AxiosInitialInstance,
  axiosPost,
  axiosSffGet,
} from './request'
import { axiosGet as axiosPayGet, axiosPost as axiosPayPost } from './services'
import recodeTelNumber from '@/utils/recodeTelNumber'
export { AxiosInitialInstance }
export interface UserLoginApiParams {
  username: string
  password: string
  captcha?: any
}

export interface UserLoginApiResponse {
  token: string
  uid: number
}

export async function postUserLogin(params: UserLoginApiParams) {
  try {
    const data = await AxiosInitialInstance.post<UserLoginApiResponse>(
      `${apiBaseurl}/v1/user/login`,
      params,
    )
    return data
  } catch (e) {
    if (e === 401 || e?.response.status === 401) {
      message.error('手机号或密码错误')
    } else if (e === 403 || e?.response.status === 403) {
      message.error('人机验证未通过')
    } else {
      message.error(e?.response?.data || '服务器内部错误')
    }
    throw e
  }
}

export interface IncomeResponse {
  bandwidthPrice?: number
  expectedIncomeThisMonth?: number
  expectedIncomeYesterday?: number
  totalIncome: number
  incomeYesterday?: number
  incomeLastMonth?: number
  incomeCurMonth?: number
}

export interface IncomeSummaryResponse {
  yesterday: number
  total: number
  last_month: number
  cur_month: number
}

export async function getMinerTotalIncome(): Promise<IncomeResponse> {
  return axiosPayGet<CommonResponseWrapper<IncomeSummaryResponse>>(
    '/income/summary',
  ).then((res) => {
    return {
      bandwidthPrice: 0,
      expectedIncomeThisMonth: 0,
      expectedIncomeYesterday: res.data.yesterday,
      totalIncome: res.data.total,
      incomeCurMonth: res.data.cur_month,
      incomeLastMonth: res.data.last_month,
    }
  })
}

export async function getAgentTotalIncome(): Promise<IncomeResponse> {
  return axiosPayGet<CommonResponseWrapper<IncomeSummaryResponse>>(
    '/income/summary',
  ).then((res) => {
    return {
      incomeYesterday: res.data.yesterday,
      totalIncome: res.data.total,
      incomeCurMonth: res.data.cur_month,
      incomeLastMonth: res.data.last_month,
    }
  })
}

export async function getAccountInfo() {
  return axiosGet('/user/account_info').then((res) => {
    res && store.dispatch(setAccoutInfo(res.data) as any)

    if (res.data.code === 0) {
      localStorage.setItem('agentAccount', res.data.agentAccount)
      localStorage.setItem('username', res.data.username)
      localStorage.setItem('realName', res.data.realName)
    }
    return res.data
  })
}

export interface DeviceIncome {
  deviceUUID: string
  income: number
  remark: string
  bandwidthPrice: number
}

export interface NewDeviceIncome {
  uuid: string
  minerIncome: number
  minerPrice: number
  minerRemark: string
  minerUsername: string
  agentRemark: string
  agentRemarkMiner: string
  agentPrice: number
  agentIncome: number
}

export interface DeviceIncomeRequest extends qs.ParsedUrlQueryInput {
  offset: number
  num: number
  startTime: number // 开始时间的Unix时间戳
  endTime: number // 结束时间的Unix时间戳
}

export interface MinerDeviceIncomeResponse {
  devices: DeviceIncome[]
  total: number
}

export interface AccountForBill {
  agentBandwidthPrice: number
  aliPay: string
  bandwidth: number
  expectedIncomeYesterday: number
  minerBandwidthPrice: number
  phoneNumber: string
  realName: string
  remark: string
  totalIncome: number
  username: string
}

export interface NewAccountForBill {
  username: string
  totalIncome: number
  remark: string
  phoneNumber: string
  incomeYesterday: number
  agentBandwidthPrice: number
  bandwidth: number
  minerBandwidthPrice: number
  realName: string
  aliPay: string
}

export interface DeviceIncomeResponse {
  list: {
    uuid: string
    minerIncome: number
    minerRemark: string
    minerPrice: number
    agentRemarkMiner: string
  }[]
  total: number
}

export type AccountForBillWithDevice = AccountForBill & {
  devices: DeviceIncome[]
}

export type NewAccountForBillWithDevice = NewAccountForBill & {
  devices: NewDeviceIncome[]
}

export interface IncomeMinerDeviceResponse {
  list: NewAccountForBillWithDevice[]
  total: number
}

export interface AgentDeviceIncomeResponse {
  accounts: AccountForBillWithDevice[]
  total: number
}

export async function getAgentIncome(params: {
  miner_usernames: string
  key_style: 'camel_case'
}) {
  params.key_style = 'camel_case'
  const { data } = await axiosPayGet<any>(
    `/income/miner_device?${qs.stringify(params)}`,
  )
  if (Object.prototype.toString.call(data) === '[object Array]') {
    return data
  }
  let list: any[] = []
  if (data.list != null) {
    list = data.list.map((i: any) => {
      let devices: any[] = []
      if (i.devices != null) {
        devices = i.devices.map((d: any) => ({
          deviceUUID: d.uuid,
          remark: d.agentRemark,
          income: d.minerIncome,
          bandwidthPrice: d.minerPrice,
        }))
      }
      return {
        ...i,
        expectedIncomeYesterday: i.incomeYesterday,
        devices,
      }
    })
  }
  return {
    list,
    total: data.total,
  }
}
export interface AccountForDevice {
  username: string
  bandwidth: number
  bandwidthPriceMonth: number
  createAt: number
  deviceCount: number
  diskSize: number
  expectedIncomeThisMonth: number
  expectedIncomeYesterday: number
  offlineTotal: number
  onlineTime: number
  onlineTotal: number
  phoneNumber: string
  remark: string
}

export interface Task {
  bandwidth: number
  expectedIncome: number
  faultNotificationOn: boolean
  remark: string
  startTime: number
  taskID: string
}

export interface Device {
  bandwidth: number
  bindTimestamp: number
  cpuType: string
  cpuNum: number
  cpuFrequency: number
  cpuInfo: string
  demand: string
  deviceName: string
  diallingType: string
  diskInfo: number
  expectedIncome: number
  evaluationScore: number
  evaluationStatus:
    | 'nonexistent'
    | 'timeout'
    | 'finished'
    | 'evaluating'
    | 'failed'
  faultNotificationOn: boolean
  ip: string
  isp: string
  lineCount: number
  lineCountOnline: number
  location: string
  memoryInfo: number
  natStatus: string
  networkQoS: string
  offlineTime: number
  onlineStatus: string
  onlineTime: number
  remark: string
  state: string
  tcpRetransmissionRatio: number
  testTime: number
  testUpBandwidth: number
  type: string
  upBandwidthBase: number
  upBandwidthPerline: number
  uuid: string
  minerPrice: number
  version: string
  disk: {
    id: string
    iops: number
    name: string
    size: number
    type: string
  }[]
  netcardInfo: {
    ip: string
    isManager: boolean
    isValid: boolean
    name: string
    speed: number
    diallingInfo: {
      connected: boolean
      failedReason: string
      ip: string
      number: number
      successed: boolean
      vlanID: string
    }[]
  }[]
}

export type DeviceWithTask = Device & { tasks: Task[] }

export interface DeviceInfoRequest extends qs.ParsedUrlQueryInput {
  offset: number
  num: number
  uuidremark: string
  location?: string
  onlineStatus: 'online' | 'offline'
}

export interface MinerDeviceInfoResponse {
  devices: DeviceWithTask[]
  total: number
}

export type AccountForDeviceWithDevice = AccountForDevice & {
  devices: Device[]
}

export interface AgentDeviceInfoResponse {
  code: number
  devices: Device[]
  total: number
}

export async function getMinerDeviceInfo(params: DeviceInfoRequest) {
  const { data } = await axiosGet<MinerDeviceInfoResponse>(
    '/device/devices_tasks?' +
      qs.stringify({
        ...params,
        sorting: 'bindTime',
        orderby: 'desc',
      }),
  )
  if (data != null && data.devices.length > 0) {
    const device_ids = data.devices
      .map((v) => v.uuid)
      .filter(Boolean)
      .join(',')
    if (device_ids) {
      const incomeRes: any = await getDeviceIncome({
        device_ids,
        key_style: 'camel_case',
      })
      if (incomeRes && incomeRes.length > 0) {
        data.devices = data.devices.map((i) => {
          const incomeOfDevice = incomeRes.find((t: any) => t.uuid === i.uuid)
          if (incomeOfDevice) {
            return {
              ...i,
              income: incomeOfDevice.minerIncome,
              bandwidthPrice: incomeOfDevice.minerPrice,
            } as any
          }
          return i
        })
      }
    }
  }
  return {
    list: data?.devices ?? [],
    total: data?.total ?? 0,
  }
}

export async function getAgentDeviceInfo(params: DeviceInfoRequest) {
  const { data } = await axiosGet<AgentDeviceInfoResponse>(
    `/device/agent/devices_tasks?${qs.stringify({
      ...params,
      sorting: 'bindTime',
      orderby: 'desc',
    })}`,
  )
  if (data != null && data.devices?.length > 0) {
    const device_ids = data.devices
      .map((v) => v.uuid)
      .filter(Boolean)
      .join(',')
    if (device_ids) {
      const incomeRes: any = await getDeviceIncome({
        device_ids,
        key_style: 'camel_case',
      })
      if (incomeRes && incomeRes.length > 0) {
        data.devices = data.devices.map((i) => {
          const incomeOfDevice = incomeRes.find((t: any) => t.uuid === i.uuid)
          if (incomeOfDevice) {
            return {
              ...i,
              minerIncome: incomeOfDevice.minerIncome,
              agentIncome: incomeOfDevice.agentIncome,
            } as any
          }
          return i
        })
      }
    }
  }
  return {
    list: data.devices ?? [],
    total: data.total ?? 0,
  }
}

export interface UpdateAgentMinerRequest {
  phoneNumber: string
  remark: string
  bandwidthPriceMonth: string
}

export async function postUpdateAgentMiner(params: UpdateAgentMinerRequest) {
  return axiosPost('/user/update_agent_miner', params)
}

export async function getInviteLink(params: { remark: string }) {
  return axiosGet<{ link: string }>(
    `/user/agent/invite_link?${qs.stringify(params)}`,
  ).then((res) => res.data)
}

export interface TotalStatisticsResponse {
  code: number
  count: number
  lineFaultCount: number
  offlineCount: number
  onlineCount: number
}

export async function getMinerTotalStatistics() {
  const res = await axiosGet<TotalStatisticsResponse>('/user/device/overview')
  return res.data
}

export async function getAgentTotalStatistics() {
  const res = await axiosGet<TotalStatisticsResponse>('/agent/device/overview')
  return res.data
}

export type RegisterDeviceRequest = {
  deviceID: string
  remark: string
  upBandwidth: number
  downBandwidth: number
  isp: string
  city: string
  province: string
  DNS?: string
  lineNumber: number
  remoteAccessIP?: string
  remoteAccessPort?: number
  remoteAccessPassword?: string
  serverDialling?: string
}

export async function registerDevice(params: RegisterDeviceRequest) {
  try {
    const res = await axiosPost<{ code: number; msg: string }>(
      '/device/register',
      params,
      {
        params: {
          _noErrorAlert: true,
        },
      },
    )
    return res.data
  } catch (e) {
    if (e === 401 || e?.response.status === 401) {
      return {
        error: 401,
        message: '登录过期，请重新登录',
      }
    } else if (e === 400 || e?.response.status === 400) {
      if (e?.response.data?.code === 10000) {
        return {
          error: 10000,
          message:
            '您的刷机镜像版本过低，请使用官方推荐的最新镜像版本刷机，下载地址 https://www.painet.work/help/doc/doc-x86',
        }
      }
      return {
        error: 400,
        message: e?.response.data ?? '节点已绑定',
      }
    }
    throw e
  }
}

export interface MinerAccountPayinfoResponse {
  name: string
  payeeAccount: string
  payInfoIDNumber: string
  payInfoMethod: string
}

export async function getMinerAccountPayinfo() {
  const res = await axiosGet<MinerAccountPayinfoResponse>(
    '/user/account_payinfo',
  )
  return res.data
}

export interface AgentAccountPayinfoResponse {
  name: string
  bank: number
  phoneNumber: string
  cardNumber: string
}

export async function getAgentAccountPayinfo() {
  const res = await axiosGet<AgentAccountPayinfoResponse>(
    '/user/agent/account_payinfo',
  )
  return res.data
}

export async function postModifyMinerAccountPayinfo(
  params: MinerAccountPayinfoResponse,
) {
  const res = await axiosPost('/user/modify_account_payinfo', params)
  return res.data
}

export async function postModifyAgentAccountPayinfo(
  params: AgentAccountPayinfoResponse,
) {
  const res = await axiosPost('/user/agent/modify_account_payinfo', params)
  return res.data
}

export interface AgentMinersResponse {
  miners: { username: string }[]
  total: number
  code: number
}

export async function getAgentMiners() {
  const res = await axiosGet<AgentMinersResponse>('/user/agent/miners')
  return res.data?.miners ?? []
}

export type FetchMinerListParams = {
  remark: string
  username: string
  offset: number
  num: number
}

export async function getAgentMinersWithIncome(params: FetchMinerListParams) {
  const { data: minerData } = await axiosGet<AgentMinersResponse>(
    `/user/agent/miners?${qs.stringify(params)}`,
  )
  if (minerData?.miners) {
    const miner_usernames = minerData.miners
      .map((v) => v.username)
      .filter(Boolean)
      .join(',')
    if (miner_usernames) {
      const incomeRes = await getAgentIncome({
        miner_usernames,
        key_style: 'camel_case',
      })
      return {
        list: minerData.miners.map((m) => {
          const incomeOfAccount = incomeRes.find(
            (t: any) => t.username === m.username,
          )
          return {
            ...m,
            ...incomeOfAccount,
          }
        }),
        total: minerData.total,
      }
    }
  }
  return {
    list: [],
    total: 0,
  }
}

export interface UserIncomeHistoryRequest extends ParsedUrlQueryInput {
  startTime?: number
  endTime?: number
  username?: string
}

export interface DeviceIncomeHistoryRequest extends ParsedUrlQueryInput {
  startTime?: number
  endTime?: number
  uuid: string
  getMiner?: boolean
}

export interface IncomeHistoryResponse {
  income: number[]
  time: number[]
  total: number
}

export async function getUserIncomeHistory(params: UserIncomeHistoryRequest) {
  params.key_style = 'camel_case'
  params.start_timestamp = params.startTime
  delete params.startTime
  params.end_timestamp = params.endTime
  delete params.endTime
  const res = await axiosPayGet<CommonResponseWrapper<IncomeHistoryResponse>>(
    `/income/user/history?${qs.stringify(params)}`,
  )
  if (res.data?.income) {
    res.data.income = res.data?.income.map((i) => Number((i / 100).toFixed(2)))
  }
  if (res.data?.income == null) {
    res.data.income = []
  }
  if (res.data?.time == null) {
    res.data.time = []
  }
  return res.data
}

export async function getDeviceIncomeHistory(
  params: DeviceIncomeHistoryRequest,
) {
  params.key_style = 'camel_case'
  params.start_timestamp = params.startTime
  params.end_timestamp = params.endTime
  params.getMiner = true
  delete params.startTime
  delete params.endTime
  const res = await axiosPayGet<CommonResponseWrapper<IncomeHistoryResponse>>(
    `/income/device/history?${qs.stringify(params)}`,
  )
  if (res.data?.income) {
    res.data.income = res.data?.income.map((i) => Number((i / 100).toFixed(2)))
  }
  return res.data
}

export async function deleteAgentMiner(params: { phoneNumber: string }) {
  const res = await axiosDelete<{}>(`/user/agent/miner?${qs.stringify(params)}`)
  return res.data
}

export interface AgentDeviceIncomeRequest extends ParsedUrlQueryInput {
  offset?: number
  num?: number
  device_ids?: string
}

interface IncomeDevice {
  list: {
    agentPrice: number
    uuid: 'string'
    agentIncome: number
    agentRemark: string
    agentRemarkMiner: string
  }[]
  total: number
}

export async function getDeviceIncome(
  params: AgentDeviceIncomeRequest,
): Promise<any> {
  params.key_style = 'camel_case'
  params.limit = params.num
  delete params.num
  const res = await axiosPayGet<CommonResponseWrapper<IncomeDevice>>(
    `/income/device?${qs.stringify(params)}`,
  )
  let devicesIncome: any[] = []
  if (res?.data?.list != null) {
    devicesIncome = res.data.list.map((i) => ({
      bandwidthPrice: i.agentPrice,
      deviceUUID: i.uuid,
      income: i.agentIncome,
      remark: i.agentRemark,
      minerRemark: i.agentRemarkMiner,
    }))
  }
  if (Object.prototype.toString.call(res.data) === '[object Array]') {
    return res.data
  }
  return {
    devicesIncome,
    total: res?.data?.total,
  }
}
export enum BillStatus {
  Unverified = 0,
  Unconfirmed = 100,
  Confirmed = 200,
  FAModified = 210,
  Settled = 300,
  FAAudited = 290,
}
export const BillStatusName = {
  [BillStatus.Unverified]: '待核验',
  [BillStatus.Unconfirmed]: '待确认',
  [BillStatus.Confirmed]: '已确认',
  [BillStatus.FAModified]: '已确认',
  [BillStatus.FAAudited]: '已确认',
  [BillStatus.Settled]: ['已支付', '已放入「我的钱包」'],
}

export function getBillStatusName(status: BillStatus, date: string): string {
  let statusName = BillStatusName[status]
  if (!statusName || typeof statusName === 'string') {
    return statusName
  }
  return moment(date).isSameOrAfter(moment('2020-10'), 'months')
    ? statusName[1]
    : statusName[0]
}

// 账单详情查询
export type ExtraBill = {
  id: string
  reason: string
  amount: number
}

export type GetMonthlyBillDetailResponse = {
  state: BillStatus
  income: {
    type: string
    start_time: number
    end_time: number
    price: number
    amount: number
    external_name: string
  }[]
  fine: ExtraBill[]
  compensation: ExtraBill[]
  tax_ratio: number
  total_after_tax: number
}

export async function getMonthlyBillDetail(params: {
  date: string // '2020-07'
}) {
  const defaultResponse: GetMonthlyBillDetailResponse = {
    state: BillStatus.Unverified,
    income: [],
    fine: [],
    compensation: [],
    tax_ratio: 0,
    total_after_tax: 0,
  }
  const res = await axiosPayGet<
    CommonResponseWrapper<GetMonthlyBillDetailResponse>
  >(
    `/console/monthly_bill/detail?${qs.stringify({
      ...params,
      _noErrorAlert: true,
    })}`,
  )
  let {
    state,
    income,
    fine,
    compensation,
    tax_ratio = 0,
    total_after_tax = 0,
  } = res.data ?? defaultResponse
  let stateName = getBillStatusName(state, params.date)

  income = convertFieldInArrayObject(income, [
    {
      field: ['start_time', 'end_time'],
      callback: (time) => moment(time * 1000).format('YYYY-MM-DD HH:mm'),
    },
    {
      field: ['amount', 'price'],
      callback: getYuanFromPoint,
    },
  ])
  const convertForFineAndCompensation = (data: ExtraBill[]) =>
    convertFieldInArrayObject(data, {
      field: 'amount',
      callback: getYuanFromPoint,
    })
  fine = convertForFineAndCompensation(fine)
  compensation = convertForFineAndCompensation(compensation)
  if (moment(params.date).isSame(moment().subtract(1, 'months'), 'months')) {
    store.dispatch(
      updateBillStatus({
        stateName,
        stateCode: state,
      }),
    )
  }
  return {
    state: stateName,
    income,
    fine,
    compensation,
    tax_ratio: Math.round(tax_ratio * 100) + '%',
    total_after_tax: Number((total_after_tax / 100).toFixed(2)),
  }
}

// 用户方账单节点详情导出
type GetMonthlyBillDetailExportItem = {
  time: number
  device_name: string
  device_uuid: string
  price_type: string
  price: number
  bandwidth: number
  income: number
  miner_account?: string
}
export type GetMonthlyBillDetailExport = GetMonthlyBillDetailExportItem[]

export type GetMonthlyBillDetailForExportRequest = { date: string }

export async function getMonthlyBillDetailForExport(
  params: GetMonthlyBillDetailForExportRequest & {
    limit: number
    offset: number
  },
) {
  const defaultResponse: GetMonthlyBillDetailExport = []
  const res = await axiosPayGet<
    CommonResponseWrapper<{
      Items: GetMonthlyBillDetailExport
      Total: number
    }>
  >(`/console/monthly_bill/inspect?${qs.stringify(params)}`)
  let data = res.data.Items ?? defaultResponse
  data = convertFieldInArrayObject(data, [
    {
      field: ['price', 'income'],
      callback: getYuanFromPoint,
    },
    {
      field: 'time',
      callback: (time) => moment(time * 1000).format('YYYY-MM-DD'),
    },
  ])
  return {
    list: data,
    total: res.data.Total,
  }
}
type Month95Res = {
  time_ranges: { start: number; end: number }[]
} & GetMonthlyBillDetailExportItem
async function getMonth95Inepect(params: GetMonthlyBillDetailForExportRequest) {
  return axiosPayGet<
    CommonResponseWrapper<{ Total: number; Items: Month95Res[] }>
  >(`/console/monthly_bill/month95_inspect?${qs.stringify(params)}`).then(
    (res) => {
      const defaultResponse: Month95Res[] = []
      let data = res.data.Items ?? defaultResponse
      data = convertFieldInArrayObject(data, [
        {
          field: ['price', 'income'],
          callback: getYuanFromPoint,
        },
        {
          field: 'time_ranges',
          callback: (arr: any) => {
            return arr
              .map((item: { start: number; end: number }) => {
                return `${formatUnixTime(
                  item.start,
                  'YYYY-MM-DD',
                )}~${formatUnixTime(item.end, 'YYYY-MM-DD')}`
              })
              .join()
          },
        },
      ])
      return data.map((item) => {
        return { ...item, time: item.time_ranges }
      })
    },
  )
}

export async function exportMonthlyBillDetail(
  params: GetMonthlyBillDetailForExportRequest,
  isMiner: boolean,
) {
  async function getAllDetail(
    params: GetMonthlyBillDetailForExportRequest,
    offset: number = 0,
  ): Promise<GetMonthlyBillDetailExport> {
    const data = await getMonthlyBillDetailForExport({
      ...params,
      limit: 100,
      offset,
    })
    if (data) {
      if (data.total - offset <= 100) {
        return data.list
      } else {
        const restList = await getAllDetail(params, offset + 100)
        return data.list.concat(restList)
      }
    }
    return []
  }

  try {
    const monthlyBillDetail = [
      ...(await getAllDetail(params)),
      ...(await getMonth95Inepect(params)),
    ]
    exportJSON(
      monthlyBillDetail.map((v) => {
        try {
          const bill: any = Object.assign({}, v)
          delete bill.price_type
          bill.miner_account =
            v.miner_account && recodeTelNumber(v.miner_account)
          return bill
        } catch (e) {
          console.error(e)
          return v
        }
      }),
      {
        fileName: `${params.date}月账单.xlsx`,
        sheetName: 'Sheet1',
        columnConfig: Object.assign(
          {
            time: { name: '时间', width: 32 },
            device_name: { name: '设备名', width: 32 },
            device_uuid: { name: '设备ID', width: 32 },
            price: { name: '价格(元/月/G)', width: 32 },
            bandwidth: { name: '95计费带宽值(Gbps)', width: 32 },
            income: { name: '收益(元)', width: 32 },
          },
          isMiner
            ? {}
            : {
                miner_account: { name: '用户账号', width: 32 },
              },
        ),
      },
    )
  } catch (e) {
    console.error(e)
    throw new Error('导出月账单失败')
  }
}

// 用户确认账单
export async function confirmBill(params: { date: string; state: BillStatus }) {
  await axiosPayPost<CommonResponseWrapper<null>>(
    `/console/monthly_bill/state/set`,
    params,
  )
}

// 获取推荐的镜像包版本
export async function getRecommendImagePackage() {
  const defaultData = {
    downloadURL: '',
    md5: '',
    version: '',
  }
  const { data } = await axiosGet<{
    version: string
    md5: string
    downloadURL: string
  }>('/device_image/recommendation')
  return data ?? defaultData
}

type SoN = string | number

export type IndicatorWithSuggestion = {
  data: SoN
  isQualified: boolean
  suggestion?: string
}

export type ReviewResult = {
  time: string
  score: number
  suggestion: string
  cpu: {
    version: IndicatorWithSuggestion
    coreNum: IndicatorWithSuggestion
  }
  memory: {
    freq: IndicatorWithSuggestion
    size: IndicatorWithSuggestion
  }
  disk: {
    name: IndicatorWithSuggestion
    type: IndicatorWithSuggestion
    iops: IndicatorWithSuggestion
    size: IndicatorWithSuggestion
  }[]
}

export async function getDeviceReview(params: { uuid: string }) {
  const { data } = await axiosSffGet<{
    code: number
    data: ReviewResult
  }>('/device/review?' + qs.stringify(params))
  return data.data
}

// 移除设备
export async function removeDevice(params: {
  deviceUUID: string
  abandonReason: string
}) {
  const { data } = await axiosPost<{
    code: number
    data: ReviewResult
  }>('/device/abandon', params)
  return data
}

export async function getBankNameByCard(cardNo: string) {
  const { data } = await axiosGet('/user/bankname?cardNo=' + cardNo)
  return data
}
