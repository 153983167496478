import { DeviceItemType } from '@/views/device-list/device'
import { DiallingType } from '@/views/dial/types'
const needManageLineTypeList: DiallingType[] = [
  '服务器上网',
  '固定公网多IP',
  '路由器上网一对一',
]
//需要去添加管理线路要满足：1.拨号类型，2，没有拨号信息且没有管理线路，这样可以保证在有拨号信息时不用强制添加管理线路
export default function useCheckManagerLine() {
  return function (deviceItem: DeviceItemType) {
    if (!deviceItem) {
      return true
    }
    const isSuchDiallingType = needManageLineTypeList.includes(
      deviceItem.diallingType,
    )
    const hasNoManageLine = !deviceItem.netcardInfo?.find((i) => i.isManager)
    function hasDialInfo() {
      if (Array.isArray(deviceItem.netcardInfo)) {
        return deviceItem.netcardInfo.some((i) => {
          return Array.isArray(i.diallingInfo) && i.diallingInfo.length > 0
        })
      } else {
        return false
      }
    }
    return isSuchDiallingType && !hasDialInfo() && hasNoManageLine
  }
}
