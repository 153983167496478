import styled from 'styled-components'
import { LineChartOutlined, InfoCircleOutlined } from '@ant-design/icons'

const StatisticContainer = styled.div`
  position: relative;
  margin: 56px 0 56px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &::before {
    content: ${({ title }: { title: string }) => `'${title}'`};
    display: inline-block;
    position: absolute;
    left: 0;
    top: -36px;
    font-size: 20px;
    color: #414141;
    font-weight: 500;
    line-height: 1;
  }
`

export const TechContainer = styled.div`
  position: relative;
  &::before {
    content: ${({ title }: { title: string }) => `'${title}'`};
    display: inline-block;
    position: absolute;
    left: 0;
    top: -36px;
    font-size: 20px;
    color: #414141;
    font-weight: 500;
    line-height: 1;
  }
`

const StatisticBox = styled.div`
  flex: 1;
  padding: 32px;
  display: flex;
  background: white;
  border-radius: 4px;
  position: relative;
`
const StatisticIcon = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 24px;
`

const StatisticInfo = styled.div`
  height: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1;
`

const StatisticValue = styled.p`
  position: relative;
  font-size: 28px;
  font-weight: 700;
`

const StatisticLabel = styled.p`
  font-size: 16px;
  color: #aaa;
  font-weight: 500;
`

const LineChartIcon = styled(LineChartOutlined)`
  color: green;
  font-size: 1.2em;
  cursor: pointer;
`

const WarnIcon = styled(InfoCircleOutlined)`
  color: #fa5555;
  font-size: 14px;
  position: absolute;
  top: -10px;
`

export {
  StatisticContainer,
  StatisticBox,
  StatisticIcon,
  StatisticLabel,
  StatisticValue,
  StatisticInfo,
  LineChartIcon,
  WarnIcon,
}
