import { Card } from 'antd'
import styled from 'styled-components'
export default styled(Card)`
  margin: 10px auto !important;
  /* box-shadow: 7px 8px 15px 4px #ddd; */
  display: flex;
  justify-content: center;
  form {
    width: 500px;
  }
`
