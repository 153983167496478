import { Card } from 'antd'
import React, { useCallback, useMemo } from 'react'
import moment, { Moment } from 'moment'
import { useImmer } from 'use-immer'
import { RangeSelector } from '@platform/fd'
import CustomTable from '@/components/CustomTable'
import { Title } from '..'
import { DatePicker } from 'antd'
import recordConfig, { RecordType } from '../common/recordConfig'

export interface Params {
  start: Moment
  end: Moment
  pageIndex: number
  pageSize: number
}

const RecordItem = (props: { type: RecordType }) => {
  const [fetchParams, setParams] = useImmer<Params>({
    start: moment().startOf('year'),
    end: moment().endOf('year'),
    pageIndex: 1,
    pageSize: 10,
  })
  const specificData = useMemo(() => recordConfig[props.type], [props.type])

  const memoedFetchParams = useMemo(() => {
    return {
      ...fetchParams,
      start: fetchParams.start.unix(),
      end: fetchParams.end.unix(),
      walletType: specificData.walletType,
    }
  }, [fetchParams, specificData.walletType])
  const useFetch = specificData.fetchHook
  const { dataSource, isLoading } = useFetch(memoedFetchParams, {
    selectFromResult: ({ data = specificData.initialData, isLoading }: any) => {
      return {
        dataSource: {
          total: data.total,
          list:
            data.list
              .concat()
              .sort(
                (a: { create: number }, b: { create: number }) =>
                  b.create - a.create,
              ) || [],
        },
        isLoading: isLoading,
      }
    },
  })
  const rangeChange = useCallback(
    (range: Moment[]) => {
      setParams((draft) => {
        draft.start = range[0]
        draft.end = range[1]
        draft.pageIndex = 1
      })
    },
    [setParams],
  )
  return (
    <>
      <Title>
        <div className="desc">{specificData.title}</div>
      </Title>
      <Card>
        <RangeSelector
          RangePicker={DatePicker.RangePicker}
          style={{ marginBottom: '16px' }}
          onRangeChange={rangeChange}
          btnArr={['THIS_YEAR', 'THIS_MONTH']}
          value={'THIS_YEAR'}
        ></RangeSelector>
        <CustomTable
          loading={isLoading}
          bordered
          rowKey={specificData.rowKey}
          columns={specificData.columns}
          dataSource={dataSource.list}
          pagination={{
            position: ['bottomRight'],
            current: fetchParams.pageIndex,
            pageSize: fetchParams.pageSize,
            total: dataSource.total,
            onChange: (page: number) => {
              setParams((draft) => {
                draft.pageIndex = page
              })
            },

            showSizeChanger: false,
            showTotal: (total: number) => `共计 ${total} 条`,
          }}
        ></CustomTable>
      </Card>
    </>
  )
}
export default RecordItem
