const apiBaseurl = `/api`
const adminApiBaseurl = `/api`
const payApiBaseurl = `/api/billing`
const walletApiBaseurl = `/api/wallet`
const noticeApiBaseurl = `/api/notice`
let accountApiBaseurl = `/api/account`
let sffApiBaseurl = `/api/bff/console/miniapp/api`
// let sffApiBaseurl = `http://127.0.0.1:7001/console/miniapp/api`
let strapiBaseurl = `/api/strapi`
let painetApiurl = 'https://api.painet.work'
let wsUrl = `wss://api.paigod.work/v1/ws`

if (process.env.REACT_APP_API_ENV !== 'production') {
  wsUrl = 'wss://api.test.paigod.work/v1/ws'
  painetApiurl = 'https://api-test.painet.work'
}

export {
  wsUrl,
  apiBaseurl,
  payApiBaseurl,
  walletApiBaseurl,
  sffApiBaseurl,
  strapiBaseurl,
  adminApiBaseurl,
  noticeApiBaseurl,
  accountApiBaseurl,
  painetApiurl,
}
