import { createSlice, PayloadAction } from '@reduxjs/toolkit'
export interface WithdrawItem {
  param_type: 1 | 2 //1表示公对公，2表示云账户
  checkbill_startday: number
  checkbill_endday: number
  getmoney_startday: number
  getmoney_endday: number
  getmoney_min: number
  getmoney_max: number
}

const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState: [
    {
      param_type: 1,
      checkbill_startday: 0,
      checkbill_endday: 0,
      getmoney_startday: 0,
      getmoney_endday: 0,
      getmoney_min: 0,
      getmoney_max: 0,
    },
    {
      param_type: 2,
      checkbill_startday: 0,
      checkbill_endday: 0,
      getmoney_startday: 0,
      getmoney_endday: 0,
      getmoney_min: 0,
      getmoney_max: 0,
    },
  ] as WithdrawItem[],
  reducers: {
    updateWithdrawParamsAction(state, action: PayloadAction<WithdrawItem[]>) {
      return action.payload.sort((a, b) => a.param_type - b.param_type)
    },
  },
})
export const withdrawReducer = withdrawSlice.reducer
export const { updateWithdrawParamsAction } = withdrawSlice.actions
