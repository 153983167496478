import { useGetUserInfoQuery } from '@/utils/slices/accountSlice'

export default function useHiddenIncome() {
  const { data: userInfo } = useGetUserInfoQuery()
  return function hiddenIncomeIfNeedVerify(
    income: React.ReactNode,
    clean: boolean = false,
  ) {
    //如果不需要去认证，则不隐藏
    if (!userInfo?.needVerify) {
      return income
    }
    if (clean) {
      return ''
    }
    return '***'
  }
}
