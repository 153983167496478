import React, { useContext, createContext } from 'react'
import { noticeType } from './Modal'
interface GlobalModalContextType {
  noticeStatus: noticeType
  ScanToMiniappModal: React.ReactNode
}

export const GlobalModalContext = createContext<GlobalModalContextType>({
  noticeStatus: '',
  ScanToMiniappModal: null,
})
export default function useGlobalModal() {
  return useContext(GlobalModalContext)
}
