import React, { useState } from 'react'
import { Button, Form, InputNumber, Checkbox, message } from 'antd'
import styled from 'styled-components'
import { useAppSelector } from '@/redux'
import NoticeModal from '@/components/NoticeModal'
import VerifyCode from '@/components/VerifyCode'
import { $ERROR_COLOR, $Link_COLOR } from '@/constants/styles'
import {
  useDoWithdrawMutation,
  WalletType,
  useGetAuthstatusQuery,
  initialAuthStatus,
  AuthStatusEnum,
} from '../walletSlice'
import { ReloadOutlined } from '@ant-design/icons'
import { QRCodeSVG } from 'qrcode.react'
const Comment = styled.div`
  margin-top: 20px;
  color: ${$ERROR_COLOR};
  margin-bottom: 10px;
  line-height: 1em;
`

const Cert = styled.div`
  background: #fafafa;
  border: 1px solid #e9e9e9;
  border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0px 0px;
  padding: 21px 20px;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 30px;
  .warn {
    color: ${(props) => props.theme.errorColor};
  }

  > div:not(:last-child) {
    margin-bottom: 14px;
  }
`

const OuterLink = (props: { filename: string; label: string }) => {
  return (
    <span
      style={{
        color: $Link_COLOR,
        cursor: 'pointer',
        textDecoration: 'underline',
      }}
      onClick={() => {
        window.open(
          `${window.location.origin}/${props.filename}.html`,
          '_blank',
        )
      }}
    >
      “{props.label}”
    </span>
  )
}

export interface WithdrawParams {
  leftAmount: number //余额
  amountLimit: [number, number] //提现额度限制
  timeRangeRaw: [number, number] //提现时间限制原始值
  timeRangeString: string[] //例本周3，本月3日
  available: boolean //是否可提现
}
interface Props {
  visible: boolean
  onFinishWithdrawal: any
  walletType: WalletType
  withdrawParams: WithdrawParams
}
export default ({
  visible,
  walletType,
  onFinishWithdrawal,
  withdrawParams,
}: Props) => {
  const {
    data: authStatus = initialAuthStatus,
    refetch,
    isFetching: fetchingAuthStatus,
  } = useGetAuthstatusQuery()
  const [toWithdraw, { isLoading: loading }] = useDoWithdrawMutation()
  const { payInfo, phoneNumber } = useAppSelector((state) => state.user)

  const [form] = Form.useForm()
  const [checked, toggleCheck] = useState(true)

  const initialValues = {
    verifyCode: '',
    amount: Math.min(withdrawParams.leftAmount, withdrawParams.amountLimit[1]),
  }

  return (
    <NoticeModal title="提现" visible={visible} closable={false}>
      {authStatus.state !== AuthStatusEnum.Completed && (
        <Cert>
          {authStatus.state < AuthStatusEnum.Completed && (
            <>
              <div>
                为优化提现体验，派享云将采用
                <span className="warn">三方平台打款</span>
                ，请<span className="warn">手机扫码</span>
                到三方平台认证，认证成功后<span className="warn">刷新状态</span>
                即可顺利提现！（三方平台为合法合规平台，会对您的信息严格保密）
              </div>
              <div
                style={{
                  display: 'flex',
                  margin: '20px',
                  justifyContent: 'center',
                }}
              >
                {authStatus.url && (
                  <QRCodeSVG value={authStatus.url} size={200} />
                )}
              </div>

              <div style={{ fontSize: '1.2em' }}>
                认证状态：<span className="warn">未认证</span>
                <Button
                  type="link"
                  icon={<ReloadOutlined />}
                  onClick={() => {
                    refetch()
                  }}
                  loading={fetchingAuthStatus}
                >
                  刷新
                </Button>
              </div>
            </>
          )}
          {authStatus.state === AuthStatusEnum.UnfittedPhoneNumber && (
            <div>
              当前手机号实名信息非您本人，请联系商务修改为您本人实名过的手机号！
            </div>
          )}
        </Cert>
      )}

      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        labelAlign="left"
        form={form}
        initialValues={initialValues}
      >
        <Form.Item label="提现金额：">
          <Form.Item
            validateTrigger="onBlur"
            noStyle
            name="amount"
            rules={[
              {
                required: true,
                message: '提现金额不能为空',
              },
              {
                type: 'number',
                max: Math.min(
                  withdrawParams.leftAmount,
                  withdrawParams.amountLimit[1],
                ),
                message: '超过提现限额或可用余额',
              },
              {
                type: 'number',
                min: withdrawParams.amountLimit[0],
                message: `提现最低限额${withdrawParams.amountLimit[0]}元`,
              },
            ]}
          >
            <InputNumber
              placeholder={`提现限额为${withdrawParams.amountLimit[0]}~${withdrawParams.amountLimit[1]}元`}
              style={{ width: '220px' }}
              precision={2}
              min={0}
            />
          </Form.Item>
          <div
            style={{
              display: 'inline-flex',
              marginLeft: '10px',
              color: 'rgba(0,0,0,0.40)',
            }}
          >
            可用余额{withdrawParams.leftAmount}元
          </div>
        </Form.Item>
        <Comment>务必确认手机号为收款人手机号，否则会导致提现失败！</Comment>
        <VerifyCode phoneNumber={phoneNumber}></VerifyCode>
      </Form>

      <div style={{ lineHeight: '1em' }}>
        <Checkbox
          checked={checked}
          style={{ marginRight: '10px' }}
          onChange={(e) => {
            toggleCheck(e.target.checked)
          }}
        />
        我已阅读并接受
        <OuterLink
          filename="partner"
          label="共享经济合作伙伴协议（1）"
        ></OuterLink>
        、
        <OuterLink
          filename="gaodeng"
          label="共享经济合作伙伴协议（2）"
        ></OuterLink>
        、
        <OuterLink
          filename="lanxi"
          label="共享经济合作伙伴协议（3）"
        ></OuterLink>
        、
        <OuterLink filename="zlb" label="共享经济合作伙伴协议（4）"></OuterLink>
      </div>
      {payInfo.collectionMethod === 'enterpriseCard' ? (
        <Comment>
          注意：提交申请且已邮寄发票后，预计{withdrawParams.timeRangeString[1]}
          前到账~。
        </Comment>
      ) : (
        <Comment>
          注意：提现限额为{withdrawParams.amountLimit[0]}~
          {withdrawParams.amountLimit[1]}
          元，提交申请后，预计{withdrawParams.timeRangeString[1]}前到账~。
        </Comment>
      )}
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}
      >
        <Button onClick={() => onFinishWithdrawal(true)}>取消</Button>
        <Button
          style={{ marginLeft: '20px' }}
          type="primary"
          loading={loading}
          disabled={!checked || authStatus.state !== AuthStatusEnum.Completed}
          onClick={() => {
            form.validateFields().then((values) => {
              toWithdraw({
                amount: Math.round(values.amount * 100),
                code: values.verifyCode,
                walletType,
              })
                .unwrap()
                .then((res) => {
                  message.success(
                    `申请提现成功，预计${withdrawParams.timeRangeString[1]}前到账~`,
                    5,
                  )
                  onFinishWithdrawal()
                })
            })
          }}
        >
          确定
        </Button>
      </div>
    </NoticeModal>
  )
}
