import { Button, Form } from 'antd'
import styled from 'styled-components'

export const RButton = styled(Button)`
  line-height: 32px;
  height: 32px;
  padding: 0 12px;
  border-radius: 4px;
  span {
    line-height: 1;
    height: 14px;
    display: inline-block;
  }
`
export const RemoveReuqiredItem = styled(Form.Item)`
  .ant-form-item-label > label::before {
    display: none !important;
  }
`
