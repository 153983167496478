import React from 'react'
import { Form, Input, Button, message } from 'antd'
import { axiosPost } from '../../../../utils/request'

import { useDispatch } from 'react-redux'
import { logout } from '../../../../redux/user/actions'
export default () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      delete values.confirmpassword
      postModifyPassword(values)
    } catch (errorInfo) {
      console.log('Failed:', errorInfo)
    }
  }

  const postModifyPassword = (values: any) => {
    axiosPost('/user/modify_password', values).then(() => {
      message.success('修改密码成功，请重新登录')
      dispatch(logout())
    })
  }
  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      labelAlign="left"
    >
      <Form.Item
        label="旧密码："
        name="oldpassword"
        rules={[
          { required: true, message: '请输入旧密码' },
          {
            pattern: /[\w!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,12}/,
            message: '密码需为8~12位，可以包含数字、字母、特殊字符',
          },
        ]}
      >
        <Input
          type="password"
          placeholder="请输入密码（8~12位，可以包含数字、字母、特殊字符）"
        />
      </Form.Item>
      <Form.Item
        label="新密码："
        name="password"
        rules={[{ required: true, message: '请输入新密码' }]}
      >
        <Input type="password" placeholder="请输入新密码" />
      </Form.Item>
      <Form.Item
        label="确认新密码："
        name="confirmpassword"
        dependencies={['password']}
        hasFeedback
        rules={[
          { required: true, message: '请再次输入密码' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject('两次输入的密码不相同！')
            },
          }),
        ]}
      >
        <Input type="password" placeholder="请再次输入密码" />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }}>
        <Button block type="primary" htmlType="submit" onClick={handleSubmit}>
          修改
        </Button>
      </Form.Item>
    </Form>
  )
}
