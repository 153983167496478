import xlsx, {
  WorkBook,
  // WorkbookProperties,
  WorkSheet,
  WritingOptions,
} from 'xlsx'

function json_to_sheet<T extends {}, K extends keyof T>(
  json: T[],
  columnHeaders?: { [P in K]: string },
) {
  if (columnHeaders) {
    const column = Object.keys(columnHeaders)
    json = json.map((v: any) => {
      const newObj: any = {}
      for (let i = 0; i < column.length; i++) {
        const key = column[i]
        const newKey = (columnHeaders as any)[key]
        if (v[key] != null) {
          newObj[newKey] = v[key]
        }
      }
      return newObj
    })
  }
  return xlsx.utils.json_to_sheet(json)
}

function sheet_to_workbook(sheet: WorkSheet, sheetName?: string): WorkBook {
  const workbook = xlsx.utils.book_new()
  xlsx.utils.book_append_sheet(workbook, sheet, sheetName)
  return workbook
}

// function workbook_to_xlsx(workbook: WorkBook): ArrayBuffer {
//   return xlsx.write(workbook, {
//     type: 'array',
//     bookType: 'xlsx'
//   })
// }

// function ab_to_blob(ab: ArrayBuffer) {
//   return new Blob([ab], {
//     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//   })
// }

// function startDownload(blob: Blob, filename: string) {
//   const aElement = document.createElement('a')
//   aElement.href = URL.createObjectURL(blob)
//   aElement.download = filename
//   const clickEvent = document.createEvent('MouseEvents')
//   clickEvent.initMouseEvent(
//     'click',
//     true,
//     false,
//     window,
//     0,
//     0,
//     0,
//     0,
//     0,
//     false,
//     false,
//     false,
//     false,
//     0,
//     null
//   )
//   aElement.dispatchEvent(clickEvent)
// }

type ColumnConfig = {
  name?: string
  width: number
}

export type ExportConfig<T> = {
  fileName: string
  sheetName?: string
  columnConfig: Partial<{ [P in keyof T]: ColumnConfig }>
} & WritingOptions

export function exportJSON<T extends object>(
  json: T[],
  config: ExportConfig<T>,
) {
  const { columnConfig, sheetName, fileName, ...writingOptions } = config
  const columnHeaders: any = {},
    columnWidth = []
  for (let key in columnConfig) {
    if (columnConfig[key]) {
      columnHeaders[key] = columnConfig[key]?.name ?? key
      columnWidth.push({ width: columnConfig[key]?.width ?? 32 })
    }
  }
  const worksheet = json_to_sheet(json, columnHeaders)
  worksheet['!cols'] = columnWidth
  const workbook = sheet_to_workbook(worksheet, sheetName)
  return xlsx.writeFile(workbook, fileName, writingOptions)
  // return startDownload(
  //   ab_to_blob(workbook_to_xlsx(sheet_to_workbook(json_to_sheet(json, columnHeaders), sheetName))),
  //   fileName
  // )
}
/**
 * parse xlsx file to Array[data]
 * @param file
 * @returns
 */
const XLSX_MIME_TYPE = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
]
export function parseExcel(file: File) {
  if (!XLSX_MIME_TYPE.includes(file.type)) {
    return Promise.reject('不支持当前文件类型')
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = function (e) {
      const data = e.target?.result

      if (!data) return Promise.reject('解析文件流失败')

      try {
        const workbook = xlsx.read(data, { type: 'binary' })
        // get all sheets data
        const jsonResult = workbook.SheetNames.reduce(
          (pre: any[], cur: string) => {
            const jsonData = xlsx.utils.sheet_to_json(workbook.Sheets[cur])
            pre = pre.concat(jsonData)
            return pre
          },
          [],
        )
        resolve(jsonResult)
      } catch (error) {
        reject(error)
      }
    }
    reader.onerror = function (e) {
      reject(reader.error)
    }
  })
}
