import React from 'react'
import { SetModal, ModalType } from '../..'
import WithdrawalModal from '../WithdrawalModal'
import { useAppSelector } from '@/redux'

import RecordItem from '../RecordItem'
import { useGetBalanceQuery, initialBalance } from '../../walletSlice'
import WithdrawTitle from '../WithdrawTitle'
import Desc from './Desc'
import useWithdrawParams from './useWithdrawParams'
export default function Weekly({
  setModal,
  modalStatus,
}: {
  setModal: SetModal
  modalStatus: ModalType
}) {
  const { data: balance = initialBalance } = useGetBalanceQuery(1)
  const { collectionInfoFilled, idenAuthenticationed } = useAppSelector(
    (state) => state.user,
  )

  const withdrawParams = useWithdrawParams()
  return (
    <div>
      <Desc></Desc>
      <WithdrawTitle
        text={`注意：每周${withdrawParams.timeRangeRaw[0]}~${withdrawParams.timeRangeRaw[1]}可提现1次，提现额度￥${withdrawParams.amountLimit[0]}~${withdrawParams.amountLimit[1]}元。`}
        disabled={!withdrawParams.available}
        onClick={() =>
          setModal(
            !idenAuthenticationed
              ? 'requiredIdentify'
              : !collectionInfoFilled
              ? 'requiredCollectionInfo'
              : 'withdrawaling',
          )
        }
        title="周结钱包"
        balance={balance}
      ></WithdrawTitle>
      <RecordItem type="weekly-entry"></RecordItem>
      <RecordItem type="weekly-withdraw"></RecordItem>
      {modalStatus === 'withdrawaling' && (
        <WithdrawalModal
          visible={!!modalStatus}
          onFinishWithdrawal={() => setModal('')}
          walletType={1}
          withdrawParams={withdrawParams}
        ></WithdrawalModal>
      )}
    </div>
  )
}
